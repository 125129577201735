p {
  margin-bottom: 1.5em;
}

dfn,
cite,
em,
i {
  font-style: italic;
}

blockquote {
  margin: 0 1.5em;
}

address {
  margin: 0 0 1.5em;
}

big {
  font-size: 125%;
}
