.deep-color {
  color: $white;
}
.primary {
  color: $primary;
}
.primary--deep {
  color: $primary--deep;
}
.primary--bkg {
  background-color: $primary;
}
.primary-deep--bkg {
  background-color: $primary--deep;
}

.secondary {
  color: $secondary;
}
.secondary--deep {
  color: $secondary--deep;
}
.secondary--bkg {
  background-color: $secondary;
}
.secondary-deep--bkg {
  background-color: $secondary--deep;
}

.tertiary {
  color: $secondary;
}
.tertiary--deep {
  color: $tertiary--deep;
}
.tertiary--bkg {
  background-color: $tertiary;
}
.tertiary-deep--bkg {
  background-color: $tertiary--deep;
}

.gray-dark-color {
  color: $gray-dark;
}

.gray-extralight--bkg {
  background-color: $gray-extralight;
}

.gray-dark--bkg {
  background-color: $gray-dark;
}
