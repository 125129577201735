body {
  font-family: $font__main;
  color: $color__text-main;
  font-size: $font__size--s;
  line-height: $line__height-factor * $font__size--s;
}

button,
input,
select,
optgroup,
textarea {
  color: $color__text-main;
  font-family: $font__main;
  line-height: $font__line-height-body;
}

p {
  font-size: $font__size--s;
  line-height: $line__height-factor * $font__size--s;
}

p.small,
p small {
  font-size: $font__size--xs;
}

label.small,
label small {
  font-size: $font__size--xs;
}

p.big-title,
h1.big-title,
.big-title {
  font-size: $font__size--xl;
}

.big-title.primary {
  color: $primary--deep;
}
.super-big-title {
  font-size: $font__size--xxl;
}
.super-big-title.primary {
  color: $primary--deep;
}
h1.page--title,
.page--title {
  font-size: $font__size--xl;
}

h2.tab--headline,
.tab--headline {
  font-size: $font__size--s;
}
.tab--headline--small {
  font-size: $font__size--s;
}

h3.section--title,
.section--title {
  font-size: $font__size--m;
}
p.info-control--sub {
  font-size: $font__size--xs;
}
