.material-icons {
  margin-right: 8px;
  vertical-align: sub;
}

.button--xl .material-icons {
  font-size: $font__size--xl;
}

.material-icons.small,
.button--xs .material-icons {
  font-size: $font__size--s;
}

.button--xxs .material-icons {
  font-size: $font__size--l;
}
