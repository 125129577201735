.leaflet-pane {
  z-index: $z__index-zero;
}

.leaflet-pane.leaflet-popup-pane {
  z-index: $z__index-top;
}

/* DON´T CHANGE, viewport height*/
.map-container {
  position: relative;
  display: table;
  width: 100%;
  height: 88vh; // calc(100hw)
  min-height: 320px;
  margin-top: 1px;
  background-color: rgb(238, 238, 238);
  z-index: $z__index-zero;
  transition: height 0.3s ease-out;
}

.pdf-map-container {
  position: relative;
  display: table;
  width: 100%;
  height: 400px; // calc(100hw)
  min-height: 320px;
  margin-top: 1px;
  background-color: rgb(238, 238, 238);
  z-index: $z__index-zero;
}

.map-frame,
.pdf-map-frame {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
}

#map_elementRef,
.map_elementRef,
.map-area {
  height: 100%;
  width: 100%;
}

.map-elementRef {
  height: 100%;

  .info {
    padding: 6px 8px;
    font: 14px/16px Arial, Helvetica, sans-serif;
    background: white;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }

  .info h4 {
    margin: 0 0 5px;
    color: #777;
  }

  .legend {
    line-height: 18px;
    color: #555;
    max-width: 200px;
    width: 100%;
  }

  .legend i {
    width: 18px;
    height: 18px;
    float: left;
    margin-right: 8px;
    opacity: 0.7;
  }
}

.leaflet-container {
  font-family: $font__main;
  color: $color__text-main;
  font-size: $font__size--s;
  line-height: $line__height-factor * $font__size--s;

  &.show-basket {
    .abacus-poi-marker:not(.inBasket) {
      display: none;
    }
  }
}

.leaflet-popup-content-wrapper .leaflet-popup-content p {
  font-family: $font__main;
  font-size: 12px;
  margin: 4px 0;
}

.leaflet-control-container .info.leaflet-control {
  margin: 4px 4px;
}

.abacus-poi-marker {

  &.inBasket {
    $dropShadowColor: #0036ff;
    -webkit-filter: drop-shadow(2px 0px 1px $dropShadowColor) drop-shadow(-2px 0px 1px $dropShadowColor) drop-shadow(0px 2px 1px $dropShadowColor) drop-shadow(0px -2px 1px $dropShadowColor);
    filter: drop-shadow(2px 0px 1px $dropShadowColor) drop-shadow(-2px 0px 1px $dropShadowColor) drop-shadow(0px 2px 1px $dropShadowColor) drop-shadow(0px -2px 1px $dropShadowColor);
  }

  img {
    width: 100% !important;
  }
}

.leaflet-popup {
  button {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 4px 8px;

    &:hover {
      filter: brightness(0.85);
    }
  }

  .popup-buttons {
    display: flex;
    justify-content: center;
    padding: 0.5rem 0;

    button {
      width: 100%;

      &:nth-child(2n+1) {
        margin-right: 2px;
      }

      .material-icons {
        margin: 0;
      }
    }
  }
}

