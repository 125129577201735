.section-title--icon {
  width: 24px;
  height: 24px;
  margin-right: 16px;
  margin-left: 8px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
}

.section-title--icon.indicadores {
  background-image: url('/assets/img/icons/indicadores_red.svg');
}

.section-title--icon.ecuacion {
  background-image: url('/assets/img/icons/ecuacion_comercial_red.svg');
}
.section-title--icon.campanias {
  background-image: url('/assets/img/icons/segmentos_red.svg');
}
.section-title--icon.geomarketing {
  background-image: url('/assets/img/icons/geomarketing_red.svg');
}
.section-title--icon.data {
  background-image: url('/assets/img/icons/data_red.svg');
}

.section-title--icon.analitica {
  background-image: url('/assets/img/icons/analitica_red.svg');
}

.section-title--icon.usuarios {
  background-image: url('/assets/img/icons/usuarios_red.svg');
}
.section-title--icon.campaigns {
  background-image: url('/assets/img/icons/campaigns_red.svg');
}

.section-title--icon.captadoras {
  background-image: url('/assets/img/icons/captadoras_red.svg');
}

ul.nav-ul li a.active span.ecuacion {
  background-image: url('/assets/img/icons/ecuacion_comercial_white.svg');
}
ul.nav-ul li a.active span.geomarketing {
  background-image: url('/assets/img/icons/geomarketing_white.svg');
}

ul.nav-ul li a.active span.data {
  background-image: url('/assets/img/icons/data_white.svg');
}

ul.nav-ul li a.active span.sector_comercial {
  background-image: url('/assets/img/icons/sector_comercial_white.svg');
}

ul.nav-ul li a.active span.segmentos {
  background-image: url('/assets/img/icons/segmentos_white.svg');
}

ul.nav-ul li a.active span.indicadores {
  background-image: url('/assets/img/icons/indicadores_white.svg');
}

ul.nav-ul li a.active span.analitica {
  background-image: url('/assets/img/icons/analitica_white.svg');
}
ul.nav-ul li a.active span.campaigns {
  background-image: url('/assets/img/icons/campaigns_white.svg');
}
ul.nav-ul li a.active span.captadoras {
  background-image: url('/assets/img/icons/captadoras_white.svg');
}
