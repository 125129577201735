body {
  background: $color__background-body; /* Fallback for when there is no custom background color defined. */
}

hr {
  background-color: $color__background-hr;
  border: 0;
  height: 1px;
  margin-bottom: 1.5em;
  display: block;
}
