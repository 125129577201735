.catastro-form {
  form {
    font-size: 12px;
    font-weight: 400;
    label {
      font-weight: 600;
    }
    .checkbox {
      display: block;
    }

    .radio-group {
      display: flex;
      flex-direction: column;
      margin: 8px 0 0 0;
    }

    .ovc-radio-button {
      margin: 5px;
    }
    .opacity-container {
      margin: 8px 8px 0 8px;
      .mat-slider-horizontal {
        width: 100%;
      }
    }
  }
}

.catastro-popup {
  width: auto;
  .leaflet-pane.leaflet-popup-pane,
  .leaflet-control-measure {
    font-size: 12px;
    letter-spacing: 0;
    max-height: 320px;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: 12px;
      letter-spacing: 0;
    }
  }

  .leaflet-popup-content-wrapper,
  .leaflet-popup-tip {
    max-height: 320px;
    overflow: auto;
  }

  .leaflet-pane.leaflet-popup-pane table.featureInfo {
    width: 100%;
    tr {
      display: inline-grid;
      float: left;
      width: 50%;
      td {
        width: 87%;
      }
    }
    tr:first-child {
      width: 25%;
      td {
        width: 25%;
      }
    }
  }

  button.leaflet-control-geocoder-icon {
    padding: 0 0;
    margin-bottom: 0;
  }
  .leaflet-control-geocoder {
    border-radius: 4px;
    background: #fff;
    input {
      margin: 8px;
    }
  }
}
