html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

html,
body {
  min-height: 100%;
}
html {
  font-size: 62.5%;
  text-rendering: auto;
  @include font-smoothing(on);
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
}

body {
  margin: 0;
  @include font-smoothing(on);
  //-webkit-font-smoothing: antialiased;
  //-moz-osx-font-smoothing: grayscale;
}

body {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.6;
  letter-spacing: 0.1px;
  letter-spacing: 0.01rem;
  font-weight: 400;
  font-style: normal;
  font-family: sans-serif;
  color: black;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  white-space: normal;
  vertical-align: baseline;
  text-rendering: optimizeLegibility;
}

button,
input,
select,
optgroup,
textarea {
  color: black;
  font-family: sans-serif;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  font-size: 16px;
  line-height: inherit;
}

p {
  font-size: inherit;
  line-height: inherit;
  font-weight: 400;
  margin: 8px 0;
}

p.small,
p small {
  font-size: small;
}

label.small,
label small {
  font-size: small;
}

p.big-title,
h1.big-title,
.big-title {
  font-size: larger;
  font-weight: 600;
}

strong {
  font-weight: 600;
}
.big-title.primary {
  color: black;
}
.super-big-title {
  font-size: larger;
  font-weight: 600;
}
.super-big-title.primary {
  color: black;
  line-height: 1;
  margin: 4px 0;
}
h1.page--title,
.page--title {
  font-size: larger;
  margin: 0 0 0.67em 0;
  font-weight: 700;
}

h2.tab--headline,
.tab--headline {
  font-size: 1rem;
  line-height: 1;
  font-weight: 600;
}
.tab--headline--small {
  font-size: 1rem;
  color: gray;
  line-height: 1;
  font-weight: 600;
}

h3.section--title,
.section--title {
  font-size: large;
  line-height: 1;
  font-weight: 600;
  margin: 0;
}

.section-icon {
  width: 24px;
  height: 24px;
  margin-right: 16px;
  margin-left: 8px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
}
p.margin--0 {
  margin: 0;
}
p.info-control--sub {
  margin: 0;
  font-size: small;
}

pre,
code {
  background: #f4f4f4;
  border: 1px solid #ddd;
  border-left: 3px solid black;
  color: #666;
  page-break-inside: avoid;
  font-family: monospace;
  font-size: 15px;
  line-height: 1.6;
  margin-top: 16px;
  margin-bottom: 40px;
  max-width: 100%;
  overflow: auto;
  padding: 1em 1.5em;
  display: block;
  word-wrap: break-word;
}
