ul,
ol {
  margin: 0 0 1.5em 3em;
}

ul {
  list-style: disc;
}

ol {
  list-style: decimal;
}

li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 1.5em;
}

dt {
  font-weight: bold;
}

dd {
  margin: 0 1.5em 1.5em;
}

ul.ul__horizontal {
  width: 100%;
  list-style: none;
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  li {
    display: inline;
    margin-left: 8px;
  }
}
ul.ul__menu {
  a {
    padding: 8px;
    text-decoration: none;
    cursor: pointer;
    text-decoration: underline;
    transition: all 0.4s ease;
    color: $gray-dark;
    &:hover {
      color: $gray-base;
    }
  }
}
.studies-toggle {
  margin: 16px 0 0 0px;
  font-size: 12px;
}

.ul__list {
  mat-list-option {
    border-bottom: 1px dotted $gray-light;
  }
  .mat-list-base .mat-list-item,
  .mat-list-base .mat-list-option {
    padding: 8px 0;
  }

  .mat-list-base {
    padding: 0;
    cursor: pointer;
    .mat-list-item,
    .mat-list-item-disabled {
      height: auto;
      p {
        line-height: 16px;
      }
    }
  }
}

.ul__accordion {
  .mat-accordion > .mat-expansion-panel-spacing {
    margin: 0;
  }
  mat-expansion-panel {
    border-bottom: 1px dotted $gray-light;
  }
  p {
    font-size: 12px;
    line-height: 16px;
  }
  .mat-expansion-panel-header {
    font-size: 12px;
  }
}
