@import '~@angular/material/theming';

/*http://mcg.mbitson.com/#!?mcgpalette0=%233f51b5*/

$angular-theme-primary: mat-palette($theme-primary);

$angular-theme-accent: mat-palette($theme-accent);

$angular-theme-warn: mat-palette($theme-warn);

// Create the theme object (a Sass map containing all of the palettes).
$angular-theme-colors: mat-light-theme(
  $angular-theme-primary,
  $angular-theme-accent,
  $angular-theme-warn
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($angular-theme-colors);
