/*http://mcg.mbitson.com/#!?mcgpalette0=%233f51b5*/
$theme-purple: (
  50: #ece5f3,
  100: #cfbde2,
  200: #af92cf,
  300: #8f66bc,
  400: #7745ad,
  500: #5f249f,
  600: #572097,
  700: #4d1b8d,
  800: #431683,
  900: #320d72,
  A100: #c2a5ff,
  A200: #9f72ff,
  A400: #7c3fff,
  A700: #6b25ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$theme-wine: (
  50: #4f1435,
  100: #ddb8ce,
  200: #c689ae,
  300: #af598d,
  400: #9d3674,
  500: #8c125c,
  600: #841054,
  700: #790d4a,
  800: #6f0a41,
  900: #5c0530,
  A100: #ff8ebe,
  A200: #ff5ba1,
  A400: #ff2883,
  A700: #ff0f75,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$theme-blue: (
  50: #e0eafc,
  100: #b3c9f8,
  200: #80a6f3,
  300: #4d82ee,
  400: #2667ea,
  500: #004ce6,
  600: #0045e3,
  700: #003cdf,
  800: #0033db,
  900: #0024d5,
  A100: #fcfdff,
  A200: #c9cfff,
  A400: #96a2ff,
  A700: #7d8bff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$theme-red: (
  50: #f8e0e3,
  100: #edb3b9,
  200: #e1808b,
  300: #d44d5c,
  400: #cb2639,
  500: #c20016,
  600: #bc0013,
  700: #b40010,
  800: #ac000c,
  900: #9f0006,
  A100: #ffc9ca,
  A200: #ff9697,
  A400: #ff6365,
  A700: #ff4a4b,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$theme-orange: (
  50: #f9ede3,
  100: #f1d1ba,
  200: #e8b28c,
  300: #de935e,
  400: #d77c3c,
  500: #d06519,
  600: #cb5d16,
  700: #c45312,
  800: #be490e,
  900: #b33708,
  A100: #ffe5de,
  A200: #ffbeab,
  A400: #ff9778,
  A700: #ff835e,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$theme-green: (
  50: #f4f7e7,
  100: #e3ecc3,
  200: #d0df9b,
  300: #bdd272,
  400: #afc954,
  500: #a1bf36,
  600: #99b930,
  700: #8fb129,
  800: #85a922,
  900: #749b16,
  A100: #f0ffcf,
  A200: #e0ff9c,
  A400: #cfff69,
  A700: #c7ff50,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
