$deep-blue: #2b00ff;
$blue: #0055ff;
$sky: #00d8fa;
$narrow-blue: #e4f0f9;
$magenta: #cc518f;
$magenta-gray: #c193b4;
$purple: #5f249f;
$deep-purple: #4c1c7f;
$narrow-purple: #e8cfde;
$light-purple: #e3ebf4;
$red: #e11b22;
$deep-red: #b2141a;
$narrow-red: #f9d1d2;
$orange: #d06519;
$deep-orange: #bb5a16;
$narrow-orange: #f5e0d1;
$yellow: #ffcc33;
$deep-yellow: #e5b72d;
$narrow-yellow: #fff4d6;
$green: #a1bf36;
$deep-green: #677a22;
$green-gray: #bad483;
$teal: #51acb8;
$khaki: #e2d7ac;
$sand: #b4a996;

$black: rgb(0, 0, 0);
$gray-base: rgb(0, 0, 0) !default;
$gray-darker: rgb(25, 25, 25) !default; // #191919
$gray-dark: rgb(102, 102, 102) !default; // #666666
$gray: rgb(153, 153, 153) !default; // #999999
$gray-light: #b2b2b2 !default; // #b2b2b2
$gray-lighter: rgb(204, 204, 204) !default; // #cccccc
$gray-extralight: rgb(229, 229, 229) !default; // #E5E5E5
$white: rgb(255, 255, 255);
$color__text-main: $gray-darker;
