@charset "UTF-8";
/*
$mat-xs: screen and (max-width: 599px);
$mat-sm: screen and (min-width: 600px) and (max-width: 959px);
$mat-md: screen and (min-width: 960px) and (max-width: 1279px);
$mat-lg: screen and (min-width: 1280px) and (max-width: 1919px);
$mat-xl: screen and (min-width: 1920px) and (max-width: 5000px);
$mat-lt-sm: screen and (max-width: 599px);
$mat-lt-md: screen and(max-width: 959px);
*/
/*
$mat-lt-lg: screen and (max-width: 1279px);
$mat-lt-xl: screen and (max-width: 1919px);
*/
/*
$mat-gt-xl: screen and (min-width: 1920px);
*/
/* > 600px*/
/* > 900px*/
/*!
 * Initialize.css (v1.4.0) is a complete and customizable collection of CSS best practices based on Normalize.css and HTML5 Boilerplate.
 * http://jeroenoomsnl.github.io/initialize-css
 *
 * HTML5 Boilerplate: https://github.com/h5bp/html5-boilerplate
 * Normalize.css: http://github.com/necolas/normalize.css
 */
/* ==========================================================================
   General
   ========================================================================== */
/**
 * 1. Set default font family to $initialize-font-family (default: sans-serif)
 * 2. Prevent adjustments of font size after orientation changes in IE on Windows Phone and in iOS.
 * 3. Configurable defaults
 */
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
html {
  box-sizing: border-box;
  font-family: sans-serif;
  /* 1 */
  line-height: 1.4;
  /* 3 */
  font-size: 1em;
  /* 3 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

/**
 * Remove the margin in all browsers
 */
body {
  margin: 0;
}

/**
 * Inheriting box-sizing
 * https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*, *:before, *:after {
  box-sizing: inherit;
}

/* ==========================================================================
   HTML5 display defenitions
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 * 1. Add the correct display in Edge, IE, and Firefox.
 * 2. Add the correct display in IE.
 */
details,
main {
  display: block;
}

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}

/**
 * Add the correct display in iOS 4-7.
 */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Add the correct display in IE 10-.
 * 1. Add the correct display in IE.
 */
template,
[hidden] {
  display: none;
}

/* ==========================================================================
   Links
   ========================================================================== */
/**
 * 1. Remove the gray background on active links in IE 10.
 * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
 */
a {
  -webkit-text-decoration-skip: objects;
  /* 2 */
}

/**
 * Remove the outline on focused links when they are also active or hovered
 * in all browsers (opinionated).
 */
a:active,
a:hover {
  outline-width: 0;
}

/* ==========================================================================
   Text-level semantics
   ========================================================================== */
/**
 * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/**
 * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
 */
b,
strong {
  font-weight: inherit;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}

/**
 * Add the correct font style in Android 4.3-.
 */
dfn {
  font-style: italic;
}

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* ==========================================================================
   Embedded content
   ========================================================================== */
/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden;
}

/* ==========================================================================
   Grouping content
   ========================================================================== */
/*
 * Address margin not present in IE 8/9 and Safari.
 */
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
 * Add the correct margin in IE 8.
 */
figure {
  margin: 1em 40px;
}

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

/* ==========================================================================
   Forms
   ========================================================================== */
/**
 * 1. Change the font styles in all browsers (opinionated).
 * 2. Remove the margin in Firefox and Safari.
 */
button,
input,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.4;
  /* 1 */
  margin: 0;
  /* 2 */
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */
button,
input {
  /* 1 */
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */
button,
html [type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
  /* 2 */
}

/**
 * Remove the inner border and padding in Firefox.
 */
button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */
button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
* Correct the padding in Firefox.
*/
fieldset {
  padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  box-sizing: border-box;
  /* 1 */
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal;
  /* 1 */
}

/**
 * 1. Add the correct display in IE 9-.
 * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
 * Remove the default vertical scrollbar in IE.
 */
textarea {
  overflow: auto;
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type=search] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
}

/**
 * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
 */
[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/* ==========================================================================
   Optional configurations
   ========================================================================== */
/**
 * Headings
 */
/**
 * Block elements
 */
/* ==========================================================================
   Print styles
   ========================================================================== */
/*http://mcg.mbitson.com/#!?mcgpalette0=%233f51b5*/
.fadeIn,
body.fadeIn,
body .fadeIn {
  -webkit-animation-name: fadeIn;
  -webkit-animation-duration: 0.5s;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-name: fadeIn;
  -moz-animation-duration: 0.5s;
  -moz-animation-fill-mode: forwards;
  -o-animation-name: fadeIn;
  -o-animation-duration: 0.5s;
  -o-animation-fill-mode: forwards;
  animation-name: fadeIn;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Firefox */
@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Safari and Chrome */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Internet Explorer */
@-ms-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Creating the animation */
@keyframes shakeMe {
  0%, 100% {
    transform: translateX(0);
  }
  20%, 60% {
    transform: translateX(-10px);
  }
  40%, 80% {
    transform: translateX(10px);
  }
}
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

* {
  -webkit-tap-highlight-color: transparent !important;
}

* {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  outline: none;
}

*:focus {
  outline: none;
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}

*,
*:before,
*:after {
  /* Inherit box-sizing to make it easier to change the property for components that leverage other behavior; see https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
  box-sizing: border-box;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

textarea:focus,
input:focus {
  outline: none;
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

html {
  /*overflow-y: scroll;
  overflow: -moz-scrollbars-vertical !important;*/
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin-bottom: 1px;
  margin-bottom: 0.1rem;
}

html,
body {
  min-height: 320px;
  height: auto;
  width: 100%;
}

html,
body {
  background-color: transparent;
}

body {
  min-width: auto;
  word-wrap: break-word;
}

body {
  background-color: #ffffff;
  -webkit-tap-highlight-color: transparent;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

img {
  height: auto;
  /* Make sure images are scaled correctly. */
  max-width: 100%;
  /* Adhere to container width. */
}

figure {
  margin: 1em 0;
  /* Extra wide images within figure tags don't overflow the content area. */
}

ul,
ol {
  margin: 0 0 1.5em 3em;
}

ul {
  list-style: disc;
}

ol {
  list-style: decimal;
}

li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 1.5em;
}

dt {
  font-weight: bold;
}

dd {
  margin: 0 1.5em 1.5em;
}

ul.ul__horizontal {
  width: 100%;
  list-style: none;
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
ul.ul__horizontal li {
  display: inline;
  margin-left: 8px;
}

ul.ul__menu a {
  padding: 8px;
  text-decoration: none;
  cursor: pointer;
  text-decoration: underline;
  transition: all 0.4s ease;
  color: #666666;
}
ul.ul__menu a:hover {
  color: black;
}

.studies-toggle {
  margin: 16px 0 0 0px;
  font-size: 12px;
}

.ul__list mat-list-option {
  border-bottom: 1px dotted #b2b2b2;
}
.ul__list .mat-list-base .mat-list-item,
.ul__list .mat-list-base .mat-list-option {
  padding: 8px 0;
}
.ul__list .mat-list-base {
  padding: 0;
  cursor: pointer;
}
.ul__list .mat-list-base .mat-list-item,
.ul__list .mat-list-base .mat-list-item-disabled {
  height: auto;
}
.ul__list .mat-list-base .mat-list-item p,
.ul__list .mat-list-base .mat-list-item-disabled p {
  line-height: 16px;
}

.ul__accordion .mat-accordion > .mat-expansion-panel-spacing {
  margin: 0;
}
.ul__accordion mat-expansion-panel {
  border-bottom: 1px dotted #b2b2b2;
}
.ul__accordion p {
  font-size: 12px;
  line-height: 16px;
}
.ul__accordion .mat-expansion-panel-header {
  font-size: 12px;
}

table {
  margin: 0 0 1.5em;
  width: 100%;
}

.content table tr td,
.content table tr th {
  width: 33%;
}

button {
  margin: 0;
  transition: all 0.6s;
  user-select: none;
  cursor: pointer;
  outline: none;
  border: none;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  padding: 0 16px;
  border-radius: 4px;
  overflow: visible;
}

button.hidden {
  display: none;
}

button.visible {
  display: block;
}

button.button--xl {
  margin: 24px auto;
  padding: 0 24px;
  line-height: 36px;
}

button.button--xs {
  font-size: 11px;
  width: auto;
  line-height: 24px;
  min-width: 56px;
  margin: 0px 0px 8px 0;
}

button.button--xxs {
  font-size: 11px;
  width: auto;
  line-height: 24px;
  min-width: 8px;
  margin: 0px 0px 0px 0;
  padding: 0 0;
}

button.mat-warn:hover {
  color: #ffffff;
}

.mini-mat-button .mat-icon {
  background-repeat: no-repeat;
  display: inline-block;
  fill: currentColor;
  height: 16px;
  width: 16px;
  font-size: 16px;
  line-height: 16px;
}
.mini-mat-button .material-icons {
  margin-right: 0;
}
.mini-mat-button .mat-icon-button {
  width: 24px;
  height: 24px;
  line-height: 24px;
}
.mini-mat-button .mat-icon-button .mat-icon {
  line-height: 16px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: #191919;
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
  transition: background-color 5000s ease-in-out 0s;
}

.mat-snack-bar-container.danger {
  background: indianred;
  color: white;
}
.mat-snack-bar-container.success {
  background: darkseagreen;
  color: white;
}
.mat-snack-bar-container.wide {
  max-width: 90%;
  width: auto;
}

.container {
  width: 100%;
  display: block;
  min-width: 100%;
  overflow: hidden;
}

.container:before,
.container:after {
  content: "";
  display: table;
  table-layout: fixed;
}

.container:after {
  clear: both;
}

.clearfix:before,
.clearfix:after {
  content: "";
  display: table;
  table-layout: fixed;
}

.clearfix:after {
  clear: both;
}

section,
.section {
  overflow: auto;
  padding: 0px 16px;
  padding: 0rem 1rem;
}

.height--m {
  min-height: 51.2px;
  min-height: 3.2rem;
}

.hidden {
  display: none;
}

.margin__auto {
  margin: auto;
}

.margin__xs {
  margin: 8px;
}

.margin__top--xs {
  margin-top: 8px;
}

.margin__top--s {
  margin-top: 16px;
}

.margin__top--m {
  margin-top: 32px;
}

.margin__top--l {
  margin-top: 40px;
}

.margin__top--xl {
  margin-top: 48px;
}

.margin__top--xxl {
  margin-top: 80px;
}

.margin__top--xxxl {
  margin-top: 120px;
}

.margin__bottom--xs {
  margin-bottom: 8px;
}

.margin__bottom--s {
  margin-bottom: 16px;
}

.margin__bottom--m {
  margin-bottom: 32px;
}

.margin__bottom--l {
  margin-bottom: 40px;
}

.margin__bottom--xl {
  margin-bottom: 48px;
}

.margin__right--s {
  margin-right: 16px;
}

.mb-0 {
  margin-bottom: 0;
}

.m-auto {
  margin: auto;
}

.mb-auto {
  margin-bottom: auto;
}

.mt-auto {
  margin-top: auto;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.p-auto {
  padding: auto;
}

.pb-auto {
  padding-bottom: auto;
}

.pt-auto {
  padding-top: auto;
}

.pl-auto {
  padding-left: auto;
}

.pr-auto {
  padding-right: auto;
}

.px-auto {
  padding-left: auto;
  padding-right: auto;
}

.py-auto {
  padding-top: auto 0;
  padding-bottom: auto 0;
}

.m-0 {
  margin: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.mt-0 {
  margin-top: 0;
}

.ml-0 {
  margin-left: 0;
}

.mr-0 {
  margin-right: 0;
}

.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.p-0 {
  padding: 0;
}

.pb-0 {
  padding-bottom: 0;
}

.pt-0 {
  padding-top: 0;
}

.pl-0 {
  padding-left: 0;
}

.pr-0 {
  padding-right: 0;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.py-0 {
  padding-top: 0 0;
  padding-bottom: 0 0;
}

.m-1 {
  margin: 0.25rem;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.mt-1 {
  margin-top: 0.25rem;
}

.ml-1 {
  margin-left: 0.25rem;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.p-1 {
  padding: 0.25rem;
}

.pb-1 {
  padding-bottom: 0.25rem;
}

.pt-1 {
  padding-top: 0.25rem;
}

.pl-1 {
  padding-left: 0.25rem;
}

.pr-1 {
  padding-right: 0.25rem;
}

.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.py-1 {
  padding-top: 0.25rem 0;
  padding-bottom: 0.25rem 0;
}

.m-2 {
  margin: 0.5rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.p-2 {
  padding: 0.5rem;
}

.pb-2 {
  padding-bottom: 0.5rem;
}

.pt-2 {
  padding-top: 0.5rem;
}

.pl-2 {
  padding-left: 0.5rem;
}

.pr-2 {
  padding-right: 0.5rem;
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.py-2 {
  padding-top: 0.5rem 0;
  padding-bottom: 0.5rem 0;
}

.m-3 {
  margin: 1rem;
}

.mb-3 {
  margin-bottom: 1rem;
}

.mt-3 {
  margin-top: 1rem;
}

.ml-3 {
  margin-left: 1rem;
}

.mr-3 {
  margin-right: 1rem;
}

.mx-3 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.my-3 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.p-3 {
  padding: 1rem;
}

.pb-3 {
  padding-bottom: 1rem;
}

.pt-3 {
  padding-top: 1rem;
}

.pl-3 {
  padding-left: 1rem;
}

.pr-3 {
  padding-right: 1rem;
}

.px-3 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-3 {
  padding-top: 1rem 0;
  padding-bottom: 1rem 0;
}

.m-4 {
  margin: 1.5rem;
}

.mb-4 {
  margin-bottom: 1.5rem;
}

.mt-4 {
  margin-top: 1.5rem;
}

.ml-4 {
  margin-left: 1.5rem;
}

.mr-4 {
  margin-right: 1.5rem;
}

.mx-4 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.my-4 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.p-4 {
  padding: 1.5rem;
}

.pb-4 {
  padding-bottom: 1.5rem;
}

.pt-4 {
  padding-top: 1.5rem;
}

.pl-4 {
  padding-left: 1.5rem;
}

.pr-4 {
  padding-right: 1.5rem;
}

.px-4 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-4 {
  padding-top: 1.5rem 0;
  padding-bottom: 1.5rem 0;
}

.m-5 {
  margin: 3rem;
}

.mb-5 {
  margin-bottom: 3rem;
}

.mt-5 {
  margin-top: 3rem;
}

.ml-5 {
  margin-left: 3rem;
}

.mr-5 {
  margin-right: 3rem;
}

.mx-5 {
  margin-left: 3rem;
  margin-right: 3rem;
}

.my-5 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.p-5 {
  padding: 3rem;
}

.pb-5 {
  padding-bottom: 3rem;
}

.pt-5 {
  padding-top: 3rem;
}

.pl-5 {
  padding-left: 3rem;
}

.pr-5 {
  padding-right: 3rem;
}

.px-5 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.py-5 {
  padding-top: 3rem 0;
  padding-bottom: 3rem 0;
}

.padding__basic {
  padding: 12.8px;
  padding: 0.8rem;
}

.z__index-deeper {
  z-index: -99;
}

.z__index-deep {
  z-index: -1;
}

.z__index-zero {
  z-index: 0;
}

.z__index-bottom {
  z-index: 1;
}

.z__index-middle {
  z-index: 99;
}

.z__index-top {
  z-index: 999;
}

.z__index-super {
  z-index: 9999;
}

@font-face {
  font-family: "Plex";
  font-weight: 200;
  font-style: normal;
  font-display: swap;
  unicode-range: U+000-5FF;
  /* Latin glyphs */
  src: url("/assets/fonts/IBMPlex/IBMPlexSans-ExtraLight.eot");
  src: local("IBM Plex Sans ExtraLight"), local("IBMPlexSans-ExtraLight"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX9KVElMYYaJe8bpLHnCwDKjR7_AI9sdP3pBmtF8A.woff2) format("woff2"), url("/assets/fonts/IBMPlex/IBMPlexSans-ExtraLight.woff2") format("wof2"), url("/assets/fonts/IBMPlex/IBMPlexSans-ExtraLight.woff") format("woff"), url("/assets/fonts/IBMPlex/IBMPlexSans-ExtraLight.ttf") format("truetype"), url("/assets/fonts/IBMPlex/IBMPlexSans-ExtraLight.eot?#iefix") format("embedded-opentype");
}
@font-face {
  font-family: "Plex";
  font-weight: 200;
  font-style: italic;
  font-display: swap;
  unicode-range: U+000-5FF;
  /* Latin glyphs */
  src: url("/assets/fonts/IBMPlex/IBMPlexSans-ExtraLightItalic.eot");
  src: local("IBM Plex Sans ExtraLight"), local("IBMPlexSans-ExtraLightItalic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX7KVElMYYaJe8bpLHnCwDKhdTm2Idce_fuJGl18QRY.woff2) format("woff2"), url("/assets/fonts/IBMPlex/IBMPlexSans-ExtraLightItalic.woff2") format("wof2"), url("/assets/fonts/IBMPlex/IBMPlexSans-ExtraLightItalic.woff") format("woff"), url("/assets/fonts/IBMPlex/IBMPlexSans-ExtraLightItalic.ttf") format("truetype"), url("/assets/fonts/IBMPlex/IBMPlexSans-ExtraLightItalic.eot?#iefix") format("embedded-opentype");
}
@font-face {
  font-family: "Plex";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  unicode-range: U+000-5FF;
  /* Latin glyphs */
  src: url("/assets/fonts/IBMPlex/IBMPlexSans-Regular.eot");
  src: local("IBM Plex Sans Regular"), local("IBMPlexSans-Regular"), url(https://fonts.gstatic.com/s/ibmplexsans/v7/zYXgKVElMYYaJe8bpLHnCwDKhdHeFaxOedc.woff2) format("woff2"), url("/assets/fonts/IBMPlex/IBMPlexSans-Regular.woff2") format("wof2"), url("/assets/fonts/IBMPlex/IBMPlexSans-Regular.woff") format("woff"), url("/assets/fonts/IBMPlex/IBMPlexSans-Regular.ttf") format("truetype"), url("/assets/fonts/IBMPlex/IBMPlexSans-Regular.eot?#iefix") format("embedded-opentype");
}
@font-face {
  font-family: "Plex";
  font-weight: 400;
  font-style: italic;
  font-display: swap;
  unicode-range: U+000-5FF;
  /* Latin glyphs */
  src: url("/assets/fonts/IBMPlex/IBMPlexSans-Italic.eot");
  src: local("IBM Plex Sans Italic"), local("IBMPlexSans-Italic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX-KVElMYYaJe8bpLHnCwDKhdTuGaZJW9XjDlN8.woff2) format("woff2"), url("/assets/fonts/IBMPlex/IBMPlexSans-Italic.woff2") format("wof2"), url("/assets/fonts/IBMPlex/IBMPlexSans-Italic.woff") format("woff"), url("/assets/fonts/IBMPlex/IBMPlexSans-Italic.ttf") format("truetype"), url("/assets/fonts/IBMPlex/IBMPlexSans-Italic.eot?#iefix") format("embedded-opentype");
}
@font-face {
  font-family: "Plex";
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  unicode-range: U+000-5FF;
  /* Latin glyphs */
  src: url("/assets/fonts/IBMPlex/IBMPlexSans-SemiBold.eot");
  src: local("IBM Plex Sans SemiBold"), local("IBMPlexSans-SemiBold"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX9KVElMYYaJe8bpLHnCwDKjQ76AIJsdP3pBmtF8A.woff2) format("woff2"), url("/assets/fonts/IBMPlex/IBMPlexSans-SemiBold.woff2") format("wof2"), url("/assets/fonts/IBMPlex/IBMPlexSans-SemiBold.woff") format("woff"), url("/assets/fonts/IBMPlex/IBMPlexSans-SemiBold.ttf") format("truetype"), url("/assets/fonts/IBMPlex/IBMPlexSans-SemiBold.eot?#iefix") format("embedded-opentype");
}
@font-face {
  font-family: "Plex";
  font-weight: 600;
  font-style: italic;
  font-display: swap;
  unicode-range: U+000-5FF;
  /* Latin glyphs */
  src: url("/assets/fonts/IBMPlex/IBMPlexSans-SemiBoldItalic.eot");
  src: local("IBM Plex Sans SemiBold Italic"), local("IBMPlexSans-SemiBoldItalic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX7KVElMYYaJe8bpLHnCwDKhdTmyIJcePfuJGl18QRY.woff2) format("woff2"), url("/assets/fonts/IBMPlex/IBMPlexSans-SemiBoldItalic.woff2") format("wof2"), url("/assets/fonts/IBMPlex/IBMPlexSans-SemiBoldItalic.woff") format("woff"), url("/assets/fonts/IBMPlex/IBMPlexSans-SemiBoldItalic.ttf") format("truetype"), url("/assets/fonts/IBMPlex/IBMPlexSans-SemiBoldItalic.eot?#iefix") format("embedded-opentype");
}
@font-face {
  font-family: "Plex";
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  unicode-range: U+000-5FF;
  /* Latin glyphs */
  src: url("/assets/fonts/IBMPlex/IBMPlexSans-Bold.eot");
  src: local("IBM Plex Sans Bold"), local("IBMPlexSans-Bold"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX9KVElMYYaJe8bpLHnCwDKjWr7AI9sdP3pBmtF8A.woff2) format("woff2"), url("/assets/fonts/IBMPlex/IBMPlexSans-Bold.woff2") format("wof2"), url("/assets/fonts/IBMPlex/IBMPlexSans-Bold.woff") format("woff"), url("/assets/fonts/IBMPlex/IBMPlexSans-Bold.ttf") format("truetype"), url("/assets/fonts/IBMPlex/IBMPlexSans-Bold.eot?#iefix") format("embedded-opentype");
}
@font-face {
  font-family: "Plex";
  font-weight: 700;
  font-style: italic;
  font-display: swap;
  unicode-range: U+000-5FF;
  /* Latin glyphs */
  src: url("/assets/fonts/IBMPlex/IBMPlexSans-BoldItalic.eot");
  src: local("IBM Plex Sans Bold Italic"), local("IBMPlexSans-BoldItalic"), url(https://fonts.gstatic.com/s/ibmplexsans/v6/zYX7KVElMYYaJe8bpLHnCwDKhdTmrINcePfuJGl18QRY.woff2) format("woff2"), url("/assets/fonts/IBMPlex/IBMPlexSans-BoldItalic.woff2") format("wof2"), url("/assets/fonts/IBMPlex/IBMPlexSans-BoldItalic.woff") format("woff"), url("/assets/fonts/IBMPlex/IBMPlexSans-BoldItalic.ttf") format("truetype"), url("/assets/fonts/IBMPlex/IBMPlexSans-BoldItalic.eot?#iefix") format("embedded-opentype");
}
p {
  margin-bottom: 1.5em;
}

dfn,
cite,
em,
i {
  font-style: italic;
}

blockquote {
  margin: 0 1.5em;
}

address {
  margin: 0 0 1.5em;
}

big {
  font-size: 125%;
}

.text__align--left {
  text-align: left;
}

.text__align--center {
  text-align: center;
}

.text__align--right {
  text-align: right;
}

.text__align--justify {
  text-align: justify;
}

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

html,
body {
  min-height: 100%;
}

html {
  font-size: 62.5%;
  text-rendering: auto;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
}

body {
  margin: 0;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.6;
  letter-spacing: 0.1px;
  letter-spacing: 0.01rem;
  font-weight: 400;
  font-style: normal;
  font-family: sans-serif;
  color: black;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  white-space: normal;
  vertical-align: baseline;
  text-rendering: optimizeLegibility;
}

button,
input,
select,
optgroup,
textarea {
  color: black;
  font-family: sans-serif;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  font-size: 16px;
  line-height: inherit;
}

p {
  font-size: inherit;
  line-height: inherit;
  font-weight: 400;
  margin: 8px 0;
}

p.small,
p small {
  font-size: small;
}

label.small,
label small {
  font-size: small;
}

p.big-title,
h1.big-title,
.big-title {
  font-size: larger;
  font-weight: 600;
}

strong {
  font-weight: 600;
}

.big-title.primary {
  color: black;
}

.super-big-title {
  font-size: larger;
  font-weight: 600;
}

.super-big-title.primary {
  color: black;
  line-height: 1;
  margin: 4px 0;
}

h1.page--title,
.page--title {
  font-size: larger;
  margin: 0 0 0.67em 0;
  font-weight: 700;
}

h2.tab--headline,
.tab--headline {
  font-size: 1rem;
  line-height: 1;
  font-weight: 600;
}

.tab--headline--small {
  font-size: 1rem;
  color: gray;
  line-height: 1;
  font-weight: 600;
}

h3.section--title,
.section--title {
  font-size: large;
  line-height: 1;
  font-weight: 600;
  margin: 0;
}

.section-icon {
  width: 24px;
  height: 24px;
  margin-right: 16px;
  margin-left: 8px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
}

p.margin--0 {
  margin: 0;
}

p.info-control--sub {
  margin: 0;
  font-size: small;
}

pre,
code {
  background: #f4f4f4;
  border: 1px solid #ddd;
  border-left: 3px solid black;
  color: #666;
  page-break-inside: avoid;
  font-family: monospace;
  font-size: 15px;
  line-height: 1.6;
  margin-top: 16px;
  margin-bottom: 40px;
  max-width: 100%;
  overflow: auto;
  padding: 1em 1.5em;
  display: block;
  word-wrap: break-word;
}

.container .mat-divider {
  margin: 8px 0;
}

.breadcrumbs-icons {
  margin-left: 8px;
  width: 20%;
  height: 56px;
  background-color: #999999;
  color: white;
  border-radius: 4px;
  text-align: center;
  display: block;
}

.breadcrumbs-icons span {
  font-size: 56px;
}

ul.ul__horizontal {
  margin: 24px 0 16px 0;
}

ul.ul__horizontal li {
  margin-left: 8px;
}

.accordion {
  margin: 0;
}

.section-title--icon {
  width: 24px;
  height: 24px;
  margin-right: 16px;
  margin-left: 8px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
}

.section-title--icon.indicadores {
  background-image: url("/assets/img/icons/indicadores_red.svg");
}

.section-title--icon.ecuacion {
  background-image: url("/assets/img/icons/ecuacion_comercial_red.svg");
}

.section-title--icon.campanias {
  background-image: url("/assets/img/icons/segmentos_red.svg");
}

.section-title--icon.geomarketing {
  background-image: url("/assets/img/icons/geomarketing_red.svg");
}

.section-title--icon.data {
  background-image: url("/assets/img/icons/data_red.svg");
}

.section-title--icon.analitica {
  background-image: url("/assets/img/icons/analitica_red.svg");
}

.section-title--icon.usuarios {
  background-image: url("/assets/img/icons/usuarios_red.svg");
}

.section-title--icon.campaigns {
  background-image: url("/assets/img/icons/campaigns_red.svg");
}

.section-title--icon.captadoras {
  background-image: url("/assets/img/icons/captadoras_red.svg");
}

ul.nav-ul li a.active span.ecuacion {
  background-image: url("/assets/img/icons/ecuacion_comercial_white.svg");
}

ul.nav-ul li a.active span.geomarketing {
  background-image: url("/assets/img/icons/geomarketing_white.svg");
}

ul.nav-ul li a.active span.data {
  background-image: url("/assets/img/icons/data_white.svg");
}

ul.nav-ul li a.active span.sector_comercial {
  background-image: url("/assets/img/icons/sector_comercial_white.svg");
}

ul.nav-ul li a.active span.segmentos {
  background-image: url("/assets/img/icons/segmentos_white.svg");
}

ul.nav-ul li a.active span.indicadores {
  background-image: url("/assets/img/icons/indicadores_white.svg");
}

ul.nav-ul li a.active span.analitica {
  background-image: url("/assets/img/icons/analitica_white.svg");
}

ul.nav-ul li a.active span.campaigns {
  background-image: url("/assets/img/icons/campaigns_white.svg");
}

ul.nav-ul li a.active span.captadoras {
  background-image: url("/assets/img/icons/captadoras_white.svg");
}

.section-title--icon {
  width: 24px;
  height: 24px;
  margin: 0 8px 0 0;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
}

ul.nav-ul {
  width: auto;
  margin: 0;
  padding: 0;
  border: 0 solid #b2b2b2;
  display: block;
}

ul.nav-ul li {
  list-style-type: none;
  position: relative;
  cursor: pointer;
}

ul.nav-ul li a {
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  padding: 0 15px;
  height: 64px;
  line-height: 64px;
  width: 100%;
  color: #191919;
  background-color: white;
  transition: all 0.4s ease;
  box-sizing: border-box;
  border: solid #b3b3b3;
  border-width: 1px 0 0;
}

ul.nav-ul li a:hover {
  color: #000;
  background-color: #e5e5e5;
}

ul.nav-ul li a.active {
  background-color: #999999;
  color: white;
}

ul.nav-ul li a span.icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  border-radius: 0px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
}

.login-container,
.copyright-container {
  position: relative;
  z-index: 10;
}

.copyright-container {
  text-align: center;
  margin: 0px;
  position: absolute;
  bottom: 0;
}
.copyright-container p {
  font-weight: 700;
  color: #ffffff;
}

@media screen and (min-width: 600px) {
  .copyright-container {
    margin: 420px auto 0 auto;
  }
}
form.login-form {
  color: white;
  font-weight: 700;
  padding: 8px;
  /* Applying the animation */
}
form.login-form input:-internal-autofill-selected {
  background-color: transparent !important;
}
form.login-form p {
  color: white;
  font-weight: 700;
}
form.login-form .mat-form-field-appearance-legacy .mat-form-field-label {
  color: white;
  font-weight: 700;
}
form.login-form .mat-input-element {
  caret-color: white;
  font-weight: 700;
  background-color: transparent;
}
form.login-form .mat-form-field-invalid .mat-input-element {
  color: white;
}
form.login-form .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: white;
}
form.login-form .login-button-container {
  margin: 0 auto 0 auto;
}
form.login-form .login-button-container button.login-button {
  width: 120px;
  margin: 16px 0;
}
@media screen and (min-width: 600px) {
  form.login-form .login-button-container {
    margin: 0 0 0 0;
    width: 120px;
  }
}
form.login-form .login-content {
  color: #ffffff;
  font-weight: 700;
  padding: 16px 16px 8px 16px;
}
form.login-form .errors {
  color: #a1bf36;
  animation-name: shakeMe;
  animation-duration: 0.5s;
}

/* > 600px*/
/* > 900px*/
/* Structure */
.pdf-table table {
  width: 100%;
  font-size: 10px;
}
.pdf-table .mat-table thead {
  background-color: whitesmoke;
}
.pdf-table tr.mat-row,
.pdf-table tr.mat-footer-row {
  height: 24px;
}
.pdf-table tr.mat-header-row {
  height: 32px;
}
.pdf-table .mat-cell,
.pdf-table .mat-footer-cell,
.pdf-table .mat-header-cell {
  font-size: 10px;
}
.pdf-table th.mat-header-cell:first-of-type,
.pdf-table td.mat-cell:first-of-type,
.pdf-table td.mat-footer-cell:first-of-type {
  padding-left: 8px;
}
.pdf-table .mat-form-field {
  font-size: 14px;
  width: 100%;
}

.pdf-report h3.media {
  font-size: 14px;
}

.pdf-stats--title {
  font-size: 12px;
  font-weight: 600;
}
.pdf-stats--title span.material-icons {
  color: #b2b2b2;
}

.content-data {
  font-size: 12px;
}
.content-data p {
  font-size: 12px;
}
.content-data .mat-list-base .mat-list-item .mat-list-item-content {
  justify-content: space-between;
  font-size: 14px;
}
.content-data .mat-list-base .mat-subheader {
  padding: 0 8px;
  height: 30px;
  color: black;
}
.content-data .mat-list-base .mat-subheader.red {
  color: #b2b2b2;
  font-weight: normal;
}
.content-data table {
  width: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
  border-collapse: collapse;
}
.content-data table tr {
  transition: all 0.6s ease;
  cursor: pointer;
}
.content-data table tr td {
  padding: 4px 8px;
  border-bottom: 1px dotted #b2b2b2;
}
.content-data table tr td:nth-child(1) {
  width: 60%;
  font-weight: 700;
}
.content-data table tr td:nth-child(1).red {
  color: #e4f0f9;
  font-weight: normal;
}
.content-data table tr td:nth-child(2),
.content-data table tr td:nth-child(3) {
  text-align: right;
}
.content-data table tr:hover {
  background-color: #e4f0f9;
}
.content-data table.without-border {
  margin-top: 0px;
}
.content-data table.without-border tr td {
  border-bottom: none;
}
.content-data hr.border-dotted-bottom {
  border-bottom: 1px dotted #cccccc;
  background-color: transparent;
  margin-top: 16px;
  margin-bottom: 0;
}

.bar-chart {
  position: relative;
  margin: 8px 8px;
  height: 24px;
}
.bar-chart .full-percent {
  width: 100%;
  background-color: #e5e5e5;
  height: 16px;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}
.bar-chart .data-percent {
  width: 25%;
  height: 16px;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}
.bar-chart .data-percent.purple {
  background-color: #5f249f;
}
.bar-chart .data-percent.yellow {
  background-color: #ffcc33;
}
.bar-chart .data-percent.green {
  background-color: #a1bf36;
}
.bar-chart .data-percent.lightgray {
  background-color: #e5e5e5;
}

.bar-chart-data {
  padding: 4px 8px;
}
.bar-chart-data.row {
  display: flex;
}
.bar-chart-data.row .column.two {
  flex: 50%;
}
.bar-chart-data.row .column.three {
  flex: 33%;
}
.bar-chart-data .bar-chart-number {
  margin-bottom: 0px;
}
.bar-chart-data .column p {
  margin: 0;
}
.bar-chart-data small {
  font-size: 10px;
}

.horizontal-bar-chart {
  position: relative;
  margin: 8px 8px;
}
.horizontal-bar-chart .horizontal-bar-chart-row {
  display: block;
}
.horizontal-bar-chart .horizontal-bar-chart-row .one {
  display: inline-block;
  width: 36%;
}
.horizontal-bar-chart .horizontal-bar-chart-row .two {
  display: inline-block;
  width: 48%;
  vertical-align: middle;
}
.horizontal-bar-chart .horizontal-bar-chart-row .three {
  display: inline-block;
  width: 16%;
  text-align: right;
}
.horizontal-bar-chart .horizontal-bar {
  height: 16px;
}
.horizontal-bar-chart .yellow {
  background-color: #ffcc33;
}
.horizontal-bar-chart .purple {
  background-color: #5f249f;
}
.horizontal-bar-chart .green {
  background-color: #a1bf36;
}

.catastro-form form {
  font-size: 12px;
  font-weight: 400;
}
.catastro-form form label {
  font-weight: 600;
}
.catastro-form form .checkbox {
  display: block;
}
.catastro-form form .radio-group {
  display: flex;
  flex-direction: column;
  margin: 8px 0 0 0;
}
.catastro-form form .ovc-radio-button {
  margin: 5px;
}
.catastro-form form .opacity-container {
  margin: 8px 8px 0 8px;
}
.catastro-form form .opacity-container .mat-slider-horizontal {
  width: 100%;
}

.catastro-popup {
  width: auto;
}
.catastro-popup .leaflet-pane.leaflet-popup-pane,
.catastro-popup .leaflet-control-measure {
  font-size: 12px;
  letter-spacing: 0;
  max-height: 320px;
}
.catastro-popup .leaflet-pane.leaflet-popup-pane h1,
.catastro-popup .leaflet-pane.leaflet-popup-pane h2,
.catastro-popup .leaflet-pane.leaflet-popup-pane h3,
.catastro-popup .leaflet-pane.leaflet-popup-pane h4,
.catastro-popup .leaflet-pane.leaflet-popup-pane h5,
.catastro-popup .leaflet-pane.leaflet-popup-pane h6,
.catastro-popup .leaflet-control-measure h1,
.catastro-popup .leaflet-control-measure h2,
.catastro-popup .leaflet-control-measure h3,
.catastro-popup .leaflet-control-measure h4,
.catastro-popup .leaflet-control-measure h5,
.catastro-popup .leaflet-control-measure h6 {
  font-size: 12px;
  letter-spacing: 0;
}
.catastro-popup .leaflet-popup-content-wrapper,
.catastro-popup .leaflet-popup-tip {
  max-height: 320px;
  overflow: auto;
}
.catastro-popup .leaflet-pane.leaflet-popup-pane table.featureInfo {
  width: 100%;
}
.catastro-popup .leaflet-pane.leaflet-popup-pane table.featureInfo tr {
  display: inline-grid;
  float: left;
  width: 50%;
}
.catastro-popup .leaflet-pane.leaflet-popup-pane table.featureInfo tr td {
  width: 87%;
}
.catastro-popup .leaflet-pane.leaflet-popup-pane table.featureInfo tr:first-child {
  width: 25%;
}
.catastro-popup .leaflet-pane.leaflet-popup-pane table.featureInfo tr:first-child td {
  width: 25%;
}
.catastro-popup button.leaflet-control-geocoder-icon {
  padding: 0 0;
  margin-bottom: 0;
}
.catastro-popup .leaflet-control-geocoder {
  border-radius: 4px;
  background: #fff;
}
.catastro-popup .leaflet-control-geocoder input {
  margin: 8px;
}

.weather-mat-accordion {
  /* > 600px*/
  /* > 900px*/
  /*$mat-lt-lg: "screen and (max-width: 1279px)";*/
  /*< 1279*/
}
.weather-mat-accordion .hidden {
  display: none;
}
.weather-mat-accordion .mat-expansion-panel-header {
  height: 56px;
  width: 100%;
  margin-bottom: 16px;
}
.weather-mat-accordion .mat-expansion-panel-header[aria-expanded=true] {
  margin-bottom: 0;
}
.weather-mat-accordion .mat-expansion-panel-header.mat-expanded {
  height: auto;
}
.weather-mat-accordion mat-expansion-panel {
  margin: 8px auto;
}
.weather-mat-accordion .closed mat-panel-title.mat-expansion-panel-header-title {
  margin-top: 8px;
  width: 100%;
}
.weather-mat-accordion .closed .mat-expansion-panel-header-description {
  margin-top: -16px;
  font-weight: 400;
  width: 100%;
}
.weather-mat-accordion mat-panel-description.mat-expansion-panel-header-description {
  color: black;
}
.weather-mat-accordion .container.mat-panel-description {
  margin-top: 8px;
  width: 100%;
  font-weight: 400;
}
.weather-mat-accordion .text__align--rigth {
  text-align: right;
}
.weather-mat-accordion .text__aling--center {
  text-align: center;
}
.weather-mat-accordion .col.inline {
  display: inline-block;
}
.weather-mat-accordion .col.center {
  margin-top: 8px;
}
.weather-mat-accordion .col.first-col {
  width: 36%;
}
.weather-mat-accordion .col.second-col, .weather-mat-accordion .col.third-col, .weather-mat-accordion .col.fourth-col, .weather-mat-accordion .col.five-colo {
  width: 16%;
}
.weather-mat-accordion .col.half {
  width: 50%;
}
.weather-mat-accordion .title-container {
  width: 100%;
}
.weather-mat-accordion p.title {
  background: #e5e5e5;
  margin-top: 24px;
  padding: 8px;
  font-weight: 700;
}
.weather-mat-accordion .bigNumber {
  font-size: 32px;
  font-weight: 700;
}
.weather-mat-accordion span.inline {
  display: inline-block;
}
.weather-mat-accordion span.inline.max {
  font-size: 18px;
  font-weight: 700;
}
.weather-mat-accordion .probPrecipitacion,
.weather-mat-accordion .rachaMax {
  font-weight: 400;
  padding-right: 16px;
}
.weather-mat-accordion .img-l {
  width: 40px;
  height: 40px;
}
.weather-mat-accordion .img-s {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}
@media screen and (max-width: 1279px) {
  .weather-mat-accordion .probPrecipitacion,
.weather-mat-accordion .rachaMax {
    display: none;
  }
  .weather-mat-accordion .col.first-col {
    width: 60%;
  }
  .weather-mat-accordion .col.second-col, .weather-mat-accordion .col.third-col {
    width: 20%;
  }
  .weather-mat-accordion .col.fourth-col, .weather-mat-accordion .col.five-col {
    width: 0%;
  }
}

/*
$mat-xs: screen and (max-width: 599px);
$mat-sm: screen and (min-width: 600px) and (max-width: 959px);
$mat-md: screen and (min-width: 960px) and (max-width: 1279px);
$mat-lg: screen and (min-width: 1280px) and (max-width: 1919px);
$mat-xl: screen and (min-width: 1920px) and (max-width: 5000px);
$mat-lt-sm: screen and (max-width: 599px);
$mat-lt-md: screen and(max-width: 959px);
*/
/*
$mat-lt-lg: screen and (max-width: 1279px);
$mat-lt-xl: screen and (max-width: 1919px);
*/
/*
$mat-gt-xl: screen and (min-width: 1920px);
*/
/* > 600px*/
/* > 900px*/
:root {
  --primary: #5f249f;
  --primary-deep: #461b75;
  --secondary: #d06519;
  --tertiary: #ffcc33;
  --accent: #e11b22;
  --warn: #a1bf36;
  --white: white;
  --black: black;
}

/* Colors for the ripple elements.*/
/* stylelint-disable-next-line material/theme-mixin-api */
/* stylelint-disable-next-line material/theme-mixin-api */
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
/*http://mcg.mbitson.com/#!?mcgpalette0=%233f51b5*/
.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #5f249f;
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e11b22;
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #a1bf36;
}

.mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #5f249f;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #e11b22;
}

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #a1bf36;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}

.mat-badge-content {
  color: white;
  background: #5f249f;
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-accent .mat-badge-content {
  background: #e11b22;
  color: white;
}

.mat-badge-warn .mat-badge-content {
  color: white;
  background: #a1bf36;
}

.mat-badge {
  position: relative;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-button, .mat-icon-button, .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color: #5f249f;
}
.mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
  color: #e11b22;
}
.mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
  color: #a1bf36;
}
.mat-button.mat-primary.mat-button-disabled, .mat-button.mat-accent.mat-button-disabled, .mat-button.mat-warn.mat-button-disabled, .mat-button.mat-button-disabled.mat-button-disabled, .mat-icon-button.mat-primary.mat-button-disabled, .mat-icon-button.mat-accent.mat-button-disabled, .mat-icon-button.mat-warn.mat-button-disabled, .mat-icon-button.mat-button-disabled.mat-button-disabled, .mat-stroked-button.mat-primary.mat-button-disabled, .mat-stroked-button.mat-accent.mat-button-disabled, .mat-stroked-button.mat-warn.mat-button-disabled, .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #5f249f;
}
.mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #e11b22;
}
.mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #a1bf36;
}
.mat-button.mat-button-disabled .mat-button-focus-overlay, .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.mat-button .mat-ripple-element, .mat-icon-button .mat-ripple-element, .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}

.mat-button-focus-overlay {
  background: black;
}

.mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}

.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  color: white;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  color: white;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  color: white;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: #5f249f;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  background-color: #e11b22;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  background-color: #a1bf36;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-flat-button.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element, .mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element, .mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element, .mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-stroked-button:not([class*=mat-elevation-z]), .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-fab:not([class*=mat-elevation-z]), .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone,
.mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  box-shadow: none;
}

.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
}

[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}

.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-checkmark {
  fill: #fafafa;
}

.mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}

.mat-checkbox-mixedmark {
  background-color: #fafafa;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #5f249f;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #e11b22;
}
.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #a1bf36;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox .mat-ripple-element {
  background-color: black;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #5f249f;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #e11b22;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #a1bf36;
}

.mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.mat-chip.mat-standard-chip::after {
  background: black;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #5f249f;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #a1bf36;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #e11b22;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-table {
  background: white;
}

.mat-table thead, .mat-table tbody, .mat-table tfoot,
mat-header-row, mat-row, mat-footer-row,
[mat-header-row], [mat-row], [mat-footer-row],
.mat-table-sticky {
  background: inherit;
}

mat-row, mat-header-row, mat-footer-row,
th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}

.mat-cell, .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}

.mat-calendar-arrow {
  border-top-color: rgba(0, 0, 0, 0.54);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-table-header {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}

.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}

.mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}

.mat-calendar-body-in-range::before {
  background: rgba(95, 36, 159, 0.2);
}

.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}

.mat-calendar-body-comparison-bridge-start::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(95, 36, 159, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-comparison-bridge-end::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(95, 36, 159, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}

.mat-calendar-body-selected {
  background-color: #5f249f;
  color: white;
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(95, 36, 159, 0.4);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(95, 36, 159, 0.3);
}

.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(225, 27, 34, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(225, 27, 34, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(225, 27, 34, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #e11b22;
  color: white;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(225, 27, 34, 0.4);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(225, 27, 34, 0.3);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(161, 191, 54, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(161, 191, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(161, 191, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #a1bf36;
  color: white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(161, 191, 54, 0.4);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(161, 191, 54, 0.3);
}

.mat-datepicker-content-touch {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-toggle-active {
  color: #5f249f;
}
.mat-datepicker-toggle-active.mat-accent {
  color: #e11b22;
}
.mat-datepicker-toggle-active.mat-warn {
  color: #a1bf36;
}

.mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}

.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}

.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}

.mat-hint {
  color: rgba(0, 0, 0, 0.6);
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #5f249f;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #e11b22;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #a1bf36;
}

.mat-focused .mat-form-field-required-marker {
  color: #e11b22;
}

.mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #5f249f;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #e11b22;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #a1bf36;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #5f249f;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #e11b22;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #a1bf36;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #a1bf36;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #a1bf36;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #a1bf36;
}

.mat-error {
  color: #a1bf36;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #5f249f;
}
.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #e11b22;
}
.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #a1bf36;
}
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #a1bf36;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}

.mat-icon.mat-primary {
  color: #5f249f;
}
.mat-icon.mat-accent {
  color: #e11b22;
}
.mat-icon.mat-warn {
  color: #a1bf36;
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-input-element {
  caret-color: #5f249f;
}
.mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-form-field.mat-accent .mat-input-element {
  caret-color: #e11b22;
}

.mat-form-field.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: #a1bf36;
}

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #a1bf36;
}

.mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}

.mat-list-item-disabled {
  background-color: #eeeeee;
}

.mat-list-option:hover, .mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}

.mat-list-single-selected-option, .mat-list-single-selected-option:hover, .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}

.mat-menu-panel {
  background: white;
}
.mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.mat-menu-item[disabled], .mat-menu-item[disabled]::after,
.mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}

.mat-menu-item .mat-icon-no-color,
.mat-menu-item-submenu-trigger::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-paginator {
  background: white;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}

.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-paginator-container {
  min-height: 56px;
}

.mat-progress-bar-background {
  fill: #d3b9ef;
}

.mat-progress-bar-buffer {
  background-color: #d3b9ef;
}

.mat-progress-bar-fill::after {
  background-color: #5f249f;
}

.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #fce6e6;
}
.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #fce6e6;
}
.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #e11b22;
}

.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f3f7e3;
}
.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f3f7e3;
}
.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #a1bf36;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #5f249f;
}
.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: #e11b22;
}
.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: #a1bf36;
}

.mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #5f249f;
}
.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #5f249f;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e11b22;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #e11b22;
}
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #a1bf36;
}
.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #a1bf36;
}
.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button .mat-ripple-element {
  background-color: black;
}

.mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}

.mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}

.mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}

.mat-select-panel {
  background: white;
}
.mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #5f249f;
}
.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #e11b22;
}
.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #a1bf36;
}
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #a1bf36;
}
.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}

.mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-drawer.mat-drawer-push {
  background-color: white;
}
.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}

[dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
[dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #e11b22;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(225, 27, 34, 0.54);
}
.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #e11b22;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #5f249f;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(95, 36, 159, 0.54);
}
.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #5f249f;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #a1bf36;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(161, 191, 54, 0.54);
}
.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #a1bf36;
}
.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}

.mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}

.mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-primary .mat-slider-track-fill,
.mat-primary .mat-slider-thumb,
.mat-primary .mat-slider-thumb-label {
  background-color: #5f249f;
}
.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.mat-primary .mat-slider-focus-ring {
  background-color: rgba(95, 36, 159, 0.2);
}

.mat-accent .mat-slider-track-fill,
.mat-accent .mat-slider-thumb,
.mat-accent .mat-slider-thumb-label {
  background-color: #e11b22;
}
.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.mat-accent .mat-slider-focus-ring {
  background-color: rgba(225, 27, 34, 0.2);
}

.mat-warn .mat-slider-track-fill,
.mat-warn .mat-slider-thumb,
.mat-warn .mat-slider-thumb-label {
  background-color: #a1bf36;
}
.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.mat-warn .mat-slider-focus-ring {
  background-color: rgba(161, 191, 54, 0.2);
}

.mat-slider:hover .mat-slider-track-background,
.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider-disabled .mat-slider-track-background,
.mat-slider-disabled .mat-slider-track-fill,
.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}

.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #5f249f;
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon-selected,
.mat-step-header.mat-accent .mat-step-icon-state-done,
.mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #e11b22;
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon-selected,
.mat-step-header.mat-warn .mat-step-icon-state-done,
.mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #a1bf36;
  color: white;
}
.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #a1bf36;
}
.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header .mat-step-label.mat-step-label-error {
  color: #a1bf36;
}

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white;
}

.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}

.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.mat-sort-header-arrow {
  color: #757575;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}

.mat-tab-label, .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-tab-group[class*=mat-background-] .mat-tab-header,
.mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(211, 185, 239, 0.3);
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #5f249f;
}
.mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(252, 230, 230, 0.3);
}
.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #e11b22;
}
.mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(243, 247, 227, 0.3);
}
.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #a1bf36;
}
.mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(211, 185, 239, 0.3);
}
.mat-tab-group.mat-background-primary > .mat-tab-header, .mat-tab-group.mat-background-primary > .mat-tab-link-container, .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #5f249f;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-links .mat-focus-indicator::before,
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-links .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(252, 230, 230, 0.3);
}
.mat-tab-group.mat-background-accent > .mat-tab-header, .mat-tab-group.mat-background-accent > .mat-tab-link-container, .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #e11b22;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-links .mat-focus-indicator::before,
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-links .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(243, 247, 227, 0.3);
}
.mat-tab-group.mat-background-warn > .mat-tab-header, .mat-tab-group.mat-background-warn > .mat-tab-link-container, .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #a1bf36;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-links .mat-focus-indicator::before,
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-links .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}

.mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-primary {
  background: #5f249f;
  color: white;
}
.mat-toolbar.mat-accent {
  background: #e11b22;
  color: white;
}
.mat-toolbar.mat-warn {
  background: #a1bf36;
  color: white;
}
.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}

.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }

  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}

.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-tree-node {
  min-height: 48px;
}

.mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-simple-snackbar-action {
  color: #e11b22;
}

.deep-color {
  color: white;
}

.primary {
  color: #5f249f;
}

.primary--deep {
  color: #461b75;
}

.primary--bkg {
  background-color: #5f249f;
}

.primary-deep--bkg {
  background-color: #461b75;
}

.secondary {
  color: #d06519;
}

.secondary--deep {
  color: #75390e;
}

.secondary--bkg {
  background-color: #d06519;
}

.secondary-deep--bkg {
  background-color: #75390e;
}

.tertiary {
  color: #d06519;
}

.tertiary--deep {
  color: #cc9900;
}

.tertiary--bkg {
  background-color: #ffcc33;
}

.tertiary-deep--bkg {
  background-color: #cc9900;
}

.gray-dark-color {
  color: #666666;
}

.gray-extralight--bkg {
  background-color: #e5e5e5;
}

.gray-dark--bkg {
  background-color: #666666;
}

.max--width {
  max-width: 1420px;
  margin: 0 auto;
}

.med--width {
  max-width: 720px;
  margin: 0 auto;
}

abbr,
acronym {
  border-bottom: 1px dotted #666666;
  cursor: help;
}

mark,
ins {
  background: #fff9c0;
  text-decoration: none;
}

/* Colors for the ripple elements.*/
/* stylelint-disable-next-line material/theme-mixin-api */
/* stylelint-disable-next-line material/theme-mixin-api */
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Plex, Arial, "Helvetica Neue", Helvetica, Verdana, sans-serif;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1, .mat-headline, .mat-typography h1 {
  font: 700 24px/36px Plex, Arial, "Helvetica Neue", Helvetica, Verdana, sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2, .mat-title, .mat-typography h2 {
  font: 700 14px/21px Plex, Arial, "Helvetica Neue", Helvetica, Verdana, sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3, .mat-subheading-2, .mat-typography h3 {
  font: 700 18px/27px Plex, Arial, "Helvetica Neue", Helvetica, Verdana, sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4, .mat-subheading-1, .mat-typography h4 {
  font: 700 14px/21px Plex, Arial, "Helvetica Neue", Helvetica, Verdana, sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5, .mat-typography h5 {
  font: 400 calc(14px * 0.83)/21px Plex, Arial, "Helvetica Neue", Helvetica, Verdana, sans-serif;
  margin: 0 0 12px;
}

.mat-h6, .mat-typography h6 {
  font: 400 calc(14px * 0.67)/21px Plex, Arial, "Helvetica Neue", Helvetica, Verdana, sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong, .mat-body-2 {
  font: 700 14px/21px Plex, Arial, "Helvetica Neue", Helvetica, Verdana, sans-serif;
  letter-spacing: normal;
}

.mat-body, .mat-body-1, .mat-typography {
  font: 400 14px/21px Plex, Arial, "Helvetica Neue", Helvetica, Verdana, sans-serif;
  letter-spacing: normal;
}
.mat-body p, .mat-body-1 p, .mat-typography p {
  margin: 0 0 12px;
}

.mat-small, .mat-caption {
  font: 400 12px/18px Plex, Arial, "Helvetica Neue", Helvetica, Verdana, sans-serif;
  letter-spacing: normal;
}

.mat-display-4, .mat-typography .mat-display-4 {
  font: 700 24px/36px Plex, Arial, "Helvetica Neue", Helvetica, Verdana, sans-serif;
  letter-spacing: normal;
  margin: 0 0 56px;
}

.mat-display-3, .mat-typography .mat-display-3 {
  font: 700 18px/27px Plex, Arial, "Helvetica Neue", Helvetica, Verdana, sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-display-2, .mat-typography .mat-display-2 {
  font: 700 16px/24px Plex, Arial, "Helvetica Neue", Helvetica, Verdana, sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-display-1, .mat-typography .mat-display-1 {
  font: 700 14px/21px Plex, Arial, "Helvetica Neue", Helvetica, Verdana, sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 14px/21px Plex, Arial, "Helvetica Neue", Helvetica, Verdana, sans-serif;
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Plex, Arial, "Helvetica Neue", Helvetica, Verdana, sans-serif;
  font-size: 14px;
  font-weight: 700;
}

.mat-button-toggle {
  font-family: Plex, Arial, "Helvetica Neue", Helvetica, Verdana, sans-serif;
}

.mat-card {
  font-family: Plex, Arial, "Helvetica Neue", Helvetica, Verdana, sans-serif;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 700;
}

.mat-card-header .mat-card-title {
  font-size: 14px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-checkbox {
  font-family: Plex, Arial, "Helvetica Neue", Helvetica, Verdana, sans-serif;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 21px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 700;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-table {
  font-family: Plex, Arial, "Helvetica Neue", Helvetica, Verdana, sans-serif;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 700;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-calendar {
  font-family: Plex, Arial, "Helvetica Neue", Helvetica, Verdana, sans-serif;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 700;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 700 14px/21px Plex, Arial, "Helvetica Neue", Helvetica, Verdana, sans-serif;
  letter-spacing: normal;
}

.mat-expansion-panel-header {
  font-family: Plex, Arial, "Helvetica Neue", Helvetica, Verdana, sans-serif;
  font-size: 14px;
  font-weight: 700;
}

.mat-expansion-panel-content {
  font: 400 14px/21px Plex, Arial, "Helvetica Neue", Helvetica, Verdana, sans-serif;
  letter-spacing: normal;
}

.mat-form-field {
  font: 400 14px/1.125 Plex, Arial, "Helvetica Neue", Helvetica, Verdana, sans-serif;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  -ms-transform: translateY(-1.28125em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  -ms-transform: translateY(-1.28124em) scale(0.75);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  -ms-transform: translateY(-1.28123em) scale(0.75);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: Plex, Arial, "Helvetica Neue", Helvetica, Verdana, sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Plex, Arial, "Helvetica Neue", Helvetica, Verdana, sans-serif;
  font-size: 12px;
}

.mat-radio-button {
  font-family: Plex, Arial, "Helvetica Neue", Helvetica, Verdana, sans-serif;
}

.mat-select {
  font-family: Plex, Arial, "Helvetica Neue", Helvetica, Verdana, sans-serif;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slide-toggle-content {
  font-family: Plex, Arial, "Helvetica Neue", Helvetica, Verdana, sans-serif;
}

.mat-slider-thumb-label-text {
  font-family: Plex, Arial, "Helvetica Neue", Helvetica, Verdana, sans-serif;
  font-size: 12px;
  font-weight: 700;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Plex, Arial, "Helvetica Neue", Helvetica, Verdana, sans-serif;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 700;
}

.mat-tab-group {
  font-family: Plex, Arial, "Helvetica Neue", Helvetica, Verdana, sans-serif;
}

.mat-tab-label, .mat-tab-link {
  font-family: Plex, Arial, "Helvetica Neue", Helvetica, Verdana, sans-serif;
  font-size: 14px;
  font-weight: 700;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 700 14px/21px Plex, Arial, "Helvetica Neue", Helvetica, Verdana, sans-serif;
  letter-spacing: normal;
  margin: 0;
}

.mat-tooltip {
  font-family: Plex, Arial, "Helvetica Neue", Helvetica, Verdana, sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-item {
  font-family: Plex, Arial, "Helvetica Neue", Helvetica, Verdana, sans-serif;
}

.mat-list-option {
  font-family: Plex, Arial, "Helvetica Neue", Helvetica, Verdana, sans-serif;
}

.mat-list-base .mat-list-item {
  font-size: 18px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 18px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Plex, Arial, "Helvetica Neue", Helvetica, Verdana, sans-serif;
  font-size: 14px;
  font-weight: 700;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: Plex, Arial, "Helvetica Neue", Helvetica, Verdana, sans-serif;
  font-size: 12px;
  font-weight: 700;
}

.mat-option {
  font-family: Plex, Arial, "Helvetica Neue", Helvetica, Verdana, sans-serif;
  font-size: 18px;
}

.mat-optgroup-label {
  font: 700 14px/21px Plex, Arial, "Helvetica Neue", Helvetica, Verdana, sans-serif;
  letter-spacing: normal;
}

.mat-simple-snackbar {
  font-family: Plex, Arial, "Helvetica Neue", Helvetica, Verdana, sans-serif;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 700;
}

.mat-tree {
  font-family: Plex, Arial, "Helvetica Neue", Helvetica, Verdana, sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop, .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

@keyframes cdk-text-field-autofill-start {
  /*!*/
}
@keyframes cdk-text-field-autofill-end {
  /*!*/
}
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

.mat-focus-indicator {
  position: relative;
}

.mat-mdc-focus-indicator {
  position: relative;
}

.material-icons {
  margin-right: 8px;
  vertical-align: sub;
}

.button--xl .material-icons {
  font-size: 24px;
}

.material-icons.small,
.button--xs .material-icons {
  font-size: 14px;
}

.button--xxs .material-icons {
  font-size: 18px;
}

body {
  font-family: Plex, Arial, "Helvetica Neue", Helvetica, Verdana, sans-serif;
  color: #202020;
  font-size: 14px;
  line-height: 21px;
}

button,
input,
select,
optgroup,
textarea {
  color: #202020;
  font-family: Plex, Arial, "Helvetica Neue", Helvetica, Verdana, sans-serif;
  line-height: 1.5;
}

p {
  font-size: 14px;
  line-height: 21px;
}

p.small,
p small {
  font-size: 12px;
}

label.small,
label small {
  font-size: 12px;
}

p.big-title,
h1.big-title,
.big-title {
  font-size: 24px;
}

.big-title.primary {
  color: #461b75;
}

.super-big-title {
  font-size: 32px;
}

.super-big-title.primary {
  color: #461b75;
}

h1.page--title,
.page--title {
  font-size: 24px;
}

h2.tab--headline,
.tab--headline {
  font-size: 14px;
}

.tab--headline--small {
  font-size: 14px;
}

h3.section--title,
.section--title {
  font-size: 16px;
}

p.info-control--sub {
  font-size: 12px;
}

body {
  background: white;
  /* Fallback for when there is no custom background color defined. */
}

hr {
  background-color: #cccccc;
  border: 0;
  height: 1px;
  margin-bottom: 1.5em;
  display: block;
}

button.mat-button-base,
button.base,
.mat-flat-button.lightgray,
.mat-raised-button.lightgray,
.mat-fab.lightgray,
.mat-mini-fab.lightgray,
button.lightgray {
  background-color: #e5e5e5;
}

button.mat-button-base:hover,
button.base:hover,
.mat-flat-button.lightgray:hover,
.mat-raised-button.lightgray:hover,
.mat-fab.lightgray:hover,
.mat-mini-fab.lightgray:hover,
button.lightgray:hover,
button.mat-button:hover,
button.mat-raised-button:hover,
button.mat-stroked-button:hover,
button.mat-flat-button:hover,
button.mat-icon-button:hover {
  background-color: #cccccc;
}

.mat-flat-button.basic,
.mat-raised-button.basic,
.mat-fab.basic,
.mat-mini-fab.basic,
button.basic {
  background-color: white;
  border: 1px solid lightgray;
}

.mat-flat-button.basic:hover,
.mat-raised-button.basic:hover,
.mat-fab.basic:hover,
.mat-mini-fab.basic:hover,
button.basic {
  background-color: #e5e5e5;
  color: black;
}

button.primary {
  color: white;
  font-weight: bold;
  background-color: #5f249f;
}

button.primary:hover,
button.mat-primary:hover {
  background: #461b75;
  cursor: pointer;
  /*
  -webkit-box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.25);
  */
}

button.warn {
  background-color: #a1bf36;
}

button.mat-warn:hover,
button.warn:hover {
  background: #5e6f20;
}

button.button--m.mat-raised-button.mat-button-base.mat-warn,
button.mat-raised-button.mat-warn {
  color: white;
}

button.mat-accent {
  background: #e11b22;
  color: white;
  font-weight: bold;
}

button.mat-accent:hover,
button.mat-accent:hover {
  background: #861014;
}

button.mat-icon-button.close {
  background: white;
}

button.mat-icon-button.close:hover {
  background: #cccccc;
}

button.mat-button-base.menu {
  background: transparent;
}

button.mat-button-base.navbar-btn {
  background: transparent;
  color: white;
  opacity: 1;
}
button.mat-button-base.navbar-btn mat-icon {
  margin: 0;
}

button.mat-button-base.top:hover {
  opacity: 0.8;
}

.mat-flat-button.mat-button-base.mat-button-disabled:hover {
  background: rgba(0, 0, 0, 0.12);
}

button.no-hover-effect:hover {
  background: inherit;
  cursor: pointer;
}

.breadcrumbs-icons {
  margin-left: 8px;
  width: 20%;
  height: 56px;
  background-color: #5f249f;
  color: white;
  border-radius: 4px;
  text-align: center;
  display: block;
}

ul.ul__menu a {
  color: #5f249f;
}
ul.ul__menu a:hover {
  color: #461b75;
}

ul.stage {
  width: auto;
  margin: 0;
  padding: 0;
  list-style: none;
  border: 0 solid #e5e5e5;
}

ul.stage li {
  list-style-type: none;
  position: relative;
  width: 100%;
}

ul.stage li a {
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  padding: 16px 16px 16px 16px;
  width: 100%;
  border: 0 solid #e5e5e5;
  border-width: 1px 0 0 0;
  color: #202020;
  background-color: white;
  transition: all 0.4s ease;
  box-sizing: border-box;
}

ul.stage li ul li a {
  padding: 8px 8px 8px 32px;
  font-weight: 400;
  font-size: 12px;
}

ul.stage li:last-child a {
  border-bottom: 1px solid #e5e5e5;
}

ul.stage li a:hover {
  color: #5f249f;
  background-color: #e5e5e5;
}

ul.stage li a.active {
  background-color: #e5e5e5;
  color: #5f249f;
}

ul.stage li a.active span.material-icons {
  color: #5f249f;
}

ul.stage li a span.material-icons {
  color: #666666;
}

ul.stage li:hover a span.material-icons {
  color: #5f249f;
}

footer {
  background-color: #461b75;
}

/* Colors for the ripple elements.*/
/* stylelint-disable-next-line material/theme-mixin-api */
/* stylelint-disable-next-line material/theme-mixin-api */
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
/*
$mat-xs: screen and (max-width: 599px);
$mat-sm: screen and (min-width: 600px) and (max-width: 959px);
$mat-md: screen and (min-width: 960px) and (max-width: 1279px);
$mat-lg: screen and (min-width: 1280px) and (max-width: 1919px);
$mat-xl: screen and (min-width: 1920px) and (max-width: 5000px);
$mat-lt-sm: screen and (max-width: 599px);
$mat-lt-md: screen and(max-width: 959px);
*/
/*
$mat-lt-lg: screen and (max-width: 1279px);
$mat-lt-xl: screen and (max-width: 1919px);
*/
/*
$mat-gt-xl: screen and (min-width: 1920px);
*/
/* > 600px*/
/* > 900px*/
:root {
  --primary: #5f249f;
  --primary-deep: #461b75;
  --secondary: #d06519;
  --tertiary: #ffcc33;
  --accent: #e11b22;
  --warn: #a1bf36;
  --white: white;
  --black: black;
}

.mat-form-field {
  width: 100%;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 32px;
}

.accordion .mat-expansion-panel {
  margin-bottom: 4px;
}

.accordion .mat-expansion-panel-header {
  padding: 0 8px;
  background: #e3ebf4;
  height: 40px;
  transition: all 0.4s ease;
}

.accordion .mat-expansion-panel-body {
  padding: 0 0px 16px !important;
}

.accordion td.mat-cell:last-of-type {
  padding-right: 0;
}

.mat-checkbox-layout {
  overflow: visible;
}

.stages-mat-tab img.brand {
  width: 100%;
  min-width: 80px;
}
.stages-mat-tab .mat-tab-label {
  min-width: 116px;
  padding: 0 16px;
  opacity: 1;
}
.stages-mat-tab .mat-tab-body-content {
  margin: 0px auto;
  overflow: hidden;
}
.stages-mat-tab .mat-tab-body.mat-tab-body-active {
  overflow-y: hidden;
}

.leaflet-pane {
  z-index: 0;
}

.leaflet-pane.leaflet-popup-pane {
  z-index: 999;
}

/* DON´T CHANGE, viewport height*/
.map-container {
  position: relative;
  display: table;
  width: 100%;
  height: 88vh;
  min-height: 320px;
  margin-top: 1px;
  background-color: #eeeeee;
  z-index: 0;
  transition: height 0.3s ease-out;
}

.pdf-map-container {
  position: relative;
  display: table;
  width: 100%;
  height: 400px;
  min-height: 320px;
  margin-top: 1px;
  background-color: #eeeeee;
  z-index: 0;
}

.map-frame,
.pdf-map-frame {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
}

#map_elementRef,
.map_elementRef,
.map-area {
  height: 100%;
  width: 100%;
}

.map-elementRef {
  height: 100%;
}
.map-elementRef .info {
  padding: 6px 8px;
  font: 14px/16px Arial, Helvetica, sans-serif;
  background: white;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
.map-elementRef .info h4 {
  margin: 0 0 5px;
  color: #777;
}
.map-elementRef .legend {
  line-height: 18px;
  color: #555;
  max-width: 200px;
  width: 100%;
}
.map-elementRef .legend i {
  width: 18px;
  height: 18px;
  float: left;
  margin-right: 8px;
  opacity: 0.7;
}

.leaflet-container {
  font-family: Plex, Arial, "Helvetica Neue", Helvetica, Verdana, sans-serif;
  color: #202020;
  font-size: 14px;
  line-height: 21px;
}
.leaflet-container.show-basket .abacus-poi-marker:not(.inBasket) {
  display: none;
}

.leaflet-popup-content-wrapper .leaflet-popup-content p {
  font-family: Plex, Arial, "Helvetica Neue", Helvetica, Verdana, sans-serif;
  font-size: 12px;
  margin: 4px 0;
}

.leaflet-control-container .info.leaflet-control {
  margin: 4px 4px;
}

.abacus-poi-marker.inBasket {
  -webkit-filter: drop-shadow(2px 0px 1px #0036ff) drop-shadow(-2px 0px 1px #0036ff) drop-shadow(0px 2px 1px #0036ff) drop-shadow(0px -2px 1px #0036ff);
  filter: drop-shadow(2px 0px 1px #0036ff) drop-shadow(-2px 0px 1px #0036ff) drop-shadow(0px 2px 1px #0036ff) drop-shadow(0px -2px 1px #0036ff);
}
.abacus-poi-marker img {
  width: 100% !important;
}

.leaflet-popup button {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 4px 8px;
}
.leaflet-popup button:hover {
  filter: brightness(0.85);
}
.leaflet-popup .popup-buttons {
  display: flex;
  justify-content: center;
  padding: 0.5rem 0;
}
.leaflet-popup .popup-buttons button {
  width: 100%;
}
.leaflet-popup .popup-buttons button:nth-child(2n+1) {
  margin-right: 2px;
}
.leaflet-popup .popup-buttons button .material-icons {
  margin: 0;
}

.left-side-nav-btn {
  align-items: center;
  display: flex;
  background: url("../../assets/img/brand/logo-white.svg") right/120px no-repeat;
  width: 157px;
}
.left-side-nav-btn button.mat-button-base {
  background: transparent;
  color: white;
}

.mat-toolbar {
  background-color: #5f249f;
}

ul.nav-ul li a {
  font-size: 14px;
  color: #202020;
}

ul.nav-ul li:last-child a {
  border-bottom: 1px solid #b2b2b2;
}

ul.nav-ul li a:hover {
  background-color: #e5e5e5;
}

ul.nav-ul li a.active {
  background-color: #5f249f;
}

.overlay-left-label .overlay {
  z-index: 999;
}

/*
$mat-xs: screen and (max-width: 599px);
$mat-sm: screen and (min-width: 600px) and (max-width: 959px);
$mat-md: screen and (min-width: 960px) and (max-width: 1279px);
$mat-lg: screen and (min-width: 1280px) and (max-width: 1919px);
$mat-xl: screen and (min-width: 1920px) and (max-width: 5000px);
$mat-lt-sm: screen and (max-width: 599px);
$mat-lt-md: screen and(max-width: 959px);
*/
/*
$mat-lt-lg: screen and (max-width: 1279px);
$mat-lt-xl: screen and (max-width: 1919px);
*/
/*
$mat-gt-xl: screen and (min-width: 1920px);
*/
/* > 600px*/
/* > 900px*/
:root {
  --primary: #5f249f;
  --primary-deep: #461b75;
  --secondary: #d06519;
  --tertiary: #ffcc33;
  --accent: #e11b22;
  --warn: #a1bf36;
  --white: white;
  --black: black;
}

.pdf-report * {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-print-color-adjust: exact !important;
  color-adjust: exact !important;
}
.pdf-report body {
  background: #b2b2b2;
  margin: 0;
  padding: 0;
  color: #202020;
}
.pdf-report div[data-size=A4] {
  position: relative;
  background-color: white;
  /* DEV API*/
  /*background-image: url('../../../assets/img/pdf/20180118-layout.webp');*/
  background-repeat: no-repeat;
  background-size: contain;
  width: 21cm;
  height: 29.7cm;
  display: block;
  margin: 0 auto;
  margin-bottom: 0.5cm;
  box-shadow: 0 0 0.5cm rgba(178, 178, 178, 0.5);
  padding: 0px 56px;
  clear: both;
  overflow: hidden;
}
.pdf-report p {
  font-size: 12px;
  line-height: 18px;
}
.pdf-report address {
  font-size: 12px;
  padding-top: 20px;
  font-style: normal;
}
.pdf-report footer {
  position: absolute;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  bottom: 0px;
  left: 0;
  right: 0;
  font-size: 12px;
  background-color: transparent;
}
.pdf-report div.hidden {
  display: none;
}
.pdf-report .page_number {
  font-size: 10px;
}
@media print {
  .pdf-report * {
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
  }
  @page {
    .pdf-report {
      margin: 0;
      size: A4 portrait;
    }
  }
  .pdf-report body,
.pdf-report div[data-size=A4] {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin: 0;
    box-shadow: 0;
  }
  .pdf-report div[data-size=A4] {
    background-image: none;
  }
  .pdf-report .hidden {
    display: none;
  }
}

/*
$mat-xs: screen and (max-width: 599px);
$mat-sm: screen and (min-width: 600px) and (max-width: 959px);
$mat-md: screen and (min-width: 960px) and (max-width: 1279px);
$mat-lg: screen and (min-width: 1280px) and (max-width: 1919px);
$mat-xl: screen and (min-width: 1920px) and (max-width: 5000px);
$mat-lt-sm: screen and (max-width: 599px);
$mat-lt-md: screen and(max-width: 959px);
*/
/*
$mat-lt-lg: screen and (max-width: 1279px);
$mat-lt-xl: screen and (max-width: 1919px);
*/
/*
$mat-gt-xl: screen and (min-width: 1920px);
*/
/* > 600px*/
/* > 900px*/
:root {
  --primary: #5f249f;
  --primary-deep: #461b75;
  --secondary: #d06519;
  --tertiary: #ffcc33;
  --accent: #e11b22;
  --warn: #a1bf36;
  --white: white;
  --black: black;
}

.pdf-report h3.media {
  font-size: 14px;
}

.pdf-stats--title {
  font-size: 12px;
  font-weight: 600;
}
.pdf-stats--title span.material-icons {
  color: #5f249f;
}

.content-data {
  font-size: 12px;
}
.content-data p {
  font-size: 12px;
}
.content-data .mat-list-base .mat-list-item .mat-list-item-content {
  justify-content: space-between;
  font-size: 14px;
}
.content-data .mat-list-base .mat-subheader {
  padding: 0 8px;
  height: 30px;
  color: black;
}
.content-data .mat-list-base .mat-subheader.red {
  color: #5f249f;
  font-weight: normal;
}
.content-data table {
  width: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
  border-collapse: collapse;
}
.content-data table tr {
  transition: all 0.6s ease;
  cursor: pointer;
}
.content-data table tr td {
  padding: 4px 8px;
  border-bottom: 1px dotted #b2b2b2;
}
.content-data table tr td:nth-child(1) {
  width: 60%;
  font-weight: 700;
}
.content-data table tr td:nth-child(1).red {
  color: #5f249f;
  font-weight: normal;
}
.content-data table tr td:nth-child(2),
.content-data table tr td:nth-child(3) {
  text-align: right;
}
.content-data table tr:hover {
  background-color: #e4f0f9;
}
.content-data table.without-border {
  margin-top: 0px;
}
.content-data table.without-border tr td {
  border-bottom: none;
}
.content-data hr.border-dotted-bottom {
  border-bottom: 1px dotted #cccccc;
  background-color: transparent;
  margin-top: 16px;
  margin-bottom: 0;
}

.bar-chart {
  position: relative;
  margin: 8px 8px;
  height: 24px;
}
.bar-chart .full-percent {
  width: 100%;
  background-color: #e5e5e5;
  height: 16px;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}
.bar-chart .data-percent {
  width: 25%;
  height: 16px;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}
.bar-chart .data-percent.purple {
  background-color: #5f249f;
}
.bar-chart .data-percent.yellow {
  background-color: #ffcc33;
}
.bar-chart .data-percent.green {
  background-color: #a1bf36;
}
.bar-chart .data-percent.lightgray {
  background-color: #e5e5e5;
}

.bar-chart-data {
  padding: 4px 8px;
}
.bar-chart-data.row {
  display: flex;
}
.bar-chart-data.row .column.two {
  flex: 50%;
}
.bar-chart-data.row .column.three {
  flex: 33%;
}
.bar-chart-data .bar-chart-number {
  margin-bottom: 0px;
}
.bar-chart-data .column p {
  margin: 0;
}
.bar-chart-data small {
  font-size: 10px;
}

.horizontal-bar-chart {
  position: relative;
  margin: 8px 8px;
}
.horizontal-bar-chart .horizontal-bar-chart-row {
  display: block;
}
.horizontal-bar-chart .horizontal-bar-chart-row .one {
  display: inline-block;
  width: 36%;
}
.horizontal-bar-chart .horizontal-bar-chart-row .two {
  display: inline-block;
  width: 48%;
  vertical-align: middle;
}
.horizontal-bar-chart .horizontal-bar-chart-row .three {
  display: inline-block;
  width: 16%;
  text-align: right;
}
.horizontal-bar-chart .horizontal-bar {
  height: 16px;
}
.horizontal-bar-chart .yellow {
  background-color: #ffcc33;
}
.horizontal-bar-chart .purple {
  background-color: #5f249f;
}
.horizontal-bar-chart .green {
  background-color: #a1bf36;
}