$primary: $purple;
$primary--deep: darken($primary, 10);
$primary--light: lighten($primary, 45);

$secondary: $orange;
$secondary--deep: darken($secondary, 20);

$tertiary: $yellow;
$tertiary--deep: darken($tertiary, 20);

$accent: $red;
$accent---deep: darken($accent, 20);

$warn: $green;
$warn---deep: darken($warn, 20);

$color__background-body: $white;
$color__background-screen: $gray-light;
$color__background-hr: #cccccc;
$color__background-button: #e6e6e6;
$color__background-pre: #eee;
$color__background-ins: #fff9c0;

$color__text-screen: $sky;
$color__text-input: $gray-dark;
$color__text-input-focus: $gray;
$color__link: $primary;
$color__link-visited: $blue;
$color__link-hover: $deep-blue;
$color__text-main: rgba(32, 32, 32, 1);

$color__border-button: #ccc #ccc #bbb;
$color__border-button-hover: #ccc #bbb #aaa;
$color__border-button-focus: #aaa #bbb #bbb;
$color__border-input: #ccc;
$color__border-abbr: $gray-dark;


:root {
  --primary: #{$primary};
  --primary-deep: #{$primary--deep};
  --secondary: #{$secondary};
  --tertiary: #{$tertiary};
  --accent: #{$accent};
  --warn: #{$warn};
  --white: #{$white};
  --black: #{$black};
}
