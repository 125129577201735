.left-side-nav-btn {
  align-items: center;
  display: flex;
  background: url('../../assets/img/brand/logo-white.svg') right/120px
    no-repeat;
  width: 157px;

  & button.mat-button-base {
    background: transparent;
    color: white;
  }
}
.mat-toolbar {
  background-color: $primary;
}

ul.nav-ul li a {
  font-size: $font__size--s;
  color: $color__text-main;
}
ul.nav-ul li:last-child a {
  border-bottom: 1px solid $gray-light;
}

ul.nav-ul li a:hover {
  background-color: $gray-extralight;
}

ul.nav-ul li a.active {
  background-color: $primary;
}

.overlay-left-label .overlay {
  z-index: $z__index-top;
}
