.fadeIn,
body.fadeIn,
body .fadeIn {
  -webkit-animation-name: fadeIn;
  -webkit-animation-duration: 0.5s;
  -webkit-animation-fill-mode: forwards;

  -moz-animation-name: fadeIn;
  -moz-animation-duration: 0.5s;
  -moz-animation-fill-mode: forwards;

  -o-animation-name: fadeIn;
  -o-animation-duration: 0.5s;
  -o-animation-fill-mode: forwards;

  animation-name: fadeIn;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox */
@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari and Chrome */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
