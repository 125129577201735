.container {
  width: 100%;
  display: block;
  min-width: 100%;
  overflow: hidden;
}
.container:before,
.container:after {
  @include clearfix;
}

.container:after {
  @include clearfix-after;
}

.clearfix:before,
.clearfix:after {
  @include clearfix;
}

.clearfix:after {
  @include clearfix-after;
}

section,
.section {
  overflow: auto;
  @include rem-fallback(padding, 0, 1);
  //padding: 0 16px;
}

.height--m {
  @include rem-fallback(min-height, 3.2);
  // min-height: 32px;
}
.hidden {
  display: none;
}
