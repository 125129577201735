@charset "utf-8";

.breadcrumbs-icons {
  margin-left: 8px;
  width: 20%;
  height: 56px;
  background-color: $gray;
  color: white;
  border-radius: 4px;
  text-align: center;
  display: block;
}
.breadcrumbs-icons span {
  font-size: 56px;
}
ul.ul__horizontal {
  margin: 24px 0 16px 0;
}
ul.ul__horizontal li {
  margin-left: 8px;
}

.accordion {
  margin: 0;
}
