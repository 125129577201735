.margin__auto {
  margin: $margin--auto;
}

.margin__xs {
  margin: $margin--xs;
}

.margin__top--xs {
  margin-top: $margin--xs;
}

.margin__top--s {
  margin-top: $margin--s;
}

.margin__top--m {
  margin-top: $margin--m;
}

.margin__top--l {
  margin-top: $margin--l;
}

.margin__top--xl {
  margin-top: $margin--xl;
}

.margin__top--xxl {
  margin-top: $margin--xxl;
}

.margin__top--xxxl {
  margin-top: $margin--xxxl;
}

.margin__bottom--xs {
  margin-bottom: $margin--xs;
}

.margin__bottom--s {
  margin-bottom: $margin--s;
}

.margin__bottom--m {
  margin-bottom: $margin--m;
}

.margin__bottom--l {
  margin-bottom: $margin--l;
}

.margin__bottom--xl {
  margin-bottom: $margin--xl;
}

.margin__right--s {
  margin-right: $margin--s;
}

.mb-0 {
  margin-bottom: 0;
}

// base space
$spacer: 1rem;
// space for each leve
$spacers: (
  auto: auto,
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
);

@each $level, $value in $spacers {
  // margins
  .m-#{$level} {
    margin: $value;
  }

  // bottom margins
  .mb-#{$level} {
    margin-bottom: $value;
  }

  // top margins
  .mt-#{$level} {
    margin-top: $value;
  }

  // left margins
  .ml-#{$level} {
    margin-left: $value;
  }

  // right margins
  .mr-#{$level} {
    margin-right: $value;
  }

  // horizontal margins
  .mx-#{$level} {
    margin-left: $value;
    margin-right: $value;
  }

  // vertical margins
  .my-#{$level} {
    margin-top: $value;
    margin-bottom: $value;
  }

  // paddings
  .p-#{$level} {
    padding: $value;
  }

  // bottom paddings
  .pb-#{$level} {
    padding-bottom: $value;
  }

  // top paddings
  .pt-#{$level} {
    padding-top: $value;
  }

  // left paddings
  .pl-#{$level} {
    padding-left: $value;
  }

  // right paddings
  .pr-#{$level} {
    padding-right: $value;
  }

  // horizontal paddings
  .px-#{$level} {
    padding-left: $value;
    padding-right: $value;
  }

  // vertical paddings
  .py-#{$level} {
    padding-top: $value 0;
    padding-bottom: $value 0;
  }
}
