button {
  margin: 0;
  transition: all 0.6s;
  user-select: none;
  cursor: pointer;
  outline: none;
  border: none;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  padding: 0 16px;
  border-radius: 4px;
  overflow: visible;
}

button.hidden {
  display: none;
}
button.visible {
  display: block;
}

// SIZES
button.button--xl {
  margin: 24px auto;
  padding: 0 24px;
  line-height: 36px;
}

button.button--xs {
  font-size: 11px;
  width: auto;
  line-height: 24px;
  min-width: 56px;
  margin: 0px 0px 8px 0;
}

button.button--xxs {
  font-size: 11px;
  width: auto;
  line-height: 24px;
  min-width: 8px;
  margin: 0px 0px 0px 0;
  padding: 0 0;
}

button.mat-warn:hover {
  color: #ffffff;
}

.mini-mat-button {
  .mat-icon {
    background-repeat: no-repeat;
    display: inline-block;
    fill: currentColor;
    height: 16px;
    width: 16px;
    font-size: 16px;
    line-height: 16px;
  }
  .material-icons {
    margin-right: 0;
  }
  .mat-icon-button {
    width: 24px;
    height: 24px;
    line-height: 24px;
    .mat-icon {
      line-height: 16px;
    }
  }
}
