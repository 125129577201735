ul.stage {
  width: auto;
  margin: 0;
  padding: 0;
  list-style: none;
  border: 0 solid $gray-extralight;
}

ul.stage li {
  list-style-type: none;
  position: relative;
  width: 100%;
}

ul.stage li a {
  text-decoration: none;
  font-size: $font__size--s;
  font-weight: 600;
  display: flex;
  align-items: center;
  padding: 16px 16px 16px 16px;
  width: 100%;
  border: 0 solid $gray-extralight;
  border-width: 1px 0 0 0;
  color: $color__text-main;
  background-color: $white;
  transition: all 0.4s ease;
  box-sizing: border-box;
}
ul.stage li ul li a {
  padding: 8px 8px 8px 32px;
  font-weight: 400;
  font-size: 12px;
}

ul.stage li:last-child a {
  border-bottom: 1px solid $gray-extralight;
}

ul.stage li a:hover {
  color: $primary;
  background-color: $gray-extralight;
}

ul.stage li a.active {
  background-color: $gray-extralight;
  color: $primary;
}
ul.stage li a.active span.material-icons {
  color: $primary;
}

ul.stage li a span.material-icons {
  color: $gray-dark;
}
ul.stage li:hover a span.material-icons {
  color: $primary;
}
