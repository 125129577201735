.mat-snack-bar-container {
  &.danger {
    background: indianred;
    color: white;
  }

  &.success {
    background: darkseagreen;
    color: white;
  }

  &.wide {
    max-width: 90%;
    width: auto;
  }
}
