/*
$mat-xs: screen and (max-width: 599px);
$mat-sm: screen and (min-width: 600px) and (max-width: 959px);
$mat-md: screen and (min-width: 960px) and (max-width: 1279px);
$mat-lg: screen and (min-width: 1280px) and (max-width: 1919px);
$mat-xl: screen and (min-width: 1920px) and (max-width: 5000px);
$mat-lt-sm: screen and (max-width: 599px);
$mat-lt-md: screen and(max-width: 959px);
*/
/*
$mat-lt-lg: screen and (max-width: 1279px);
$mat-lt-xl: screen and (max-width: 1919px);
*/
/*
$mat-gt-xl: screen and (min-width: 1920px);
*/
/* > 600px*/
/* > 900px*/
:root {
  --primary: #5f249f;
  --primary-deep: #461b75;
  --secondary: #d06519;
  --tertiary: #ffcc33;
  --accent: #e11b22;
  --warn: #a1bf36;
  --white: white;
  --black: black;
}