.weather-mat-accordion {
  .hidden {
    display: none;
  }
  .mat-expansion-panel-header {
    height: 56px;
    width: 100%;
    margin-bottom: 16px;
  }

  .mat-expansion-panel-header[aria-expanded='true'] {
    margin-bottom: 0;
  }

  .mat-expansion-panel-header.mat-expanded {
    height: auto;
  }

  mat-expansion-panel {
    margin: 8px auto;
  }

  .closed mat-panel-title.mat-expansion-panel-header-title {
    margin-top: 8px;
    width: 100%;
  }

  .closed .mat-expansion-panel-header-description {
    margin-top: -16px;
    font-weight: 400;
    width: 100%;
  }

  mat-panel-description.mat-expansion-panel-header-description {
    color: black;
  }

  .container.mat-panel-description {
    margin-top: 8px;
    width: 100%;
    font-weight: 400;
  }

  .text__align--rigth {
    text-align: right;
  }
  .text__aling--center {
    text-align: center;
  }
  .col {
    &.inline {
      display: inline-block;
    }
    &.center {
      margin-top: 8px;
    }
    &.first-col {
      width: 36%;
    }
    &.second-col,
    &.third-col,
    &.fourth-col,
    &.five-colo {
      width: 16%;
    }
    &.half {
      width: 50%;
    }
  }
  .title-container {
    width: 100%;
  }

  p.title {
    background: #e5e5e5;
    margin-top: 24px;
    padding: 8px;
    font-weight: 700;
  }
  .bigNumber {
    font-size: 32px;
    font-weight: 700;
  }

  span.inline {
    display: inline-block;

    &.max {
      font-size: 18px;
      font-weight: 700;
    }
  }
  .probPrecipitacion,
  .rachaMax {
    font-weight: 400;
    padding-right: 16px;
  }

  .img-l {
    width: 40px;
    height: 40px;
  }
  .img-s {
    width: 24px;
    height: 24px;
    margin-right: 16px;
  }
  /* > 600px*/
  @media #{ $mat-gt-xs } {
  }

  /* > 900px*/
  @media #{$mat-gt-sm} {
  }

  /*$mat-lt-lg: "screen and (max-width: 1279px)";*/
  /*< 1279*/
  @media #{$mat-lt-lg} {
    .probPrecipitacion,
    .rachaMax {
      display: none;
    }
    .col {
      &.first-col {
        width: 60%;
      }
      &.second-col,
      &.third-col {
        width: 20%;
      }
      &.fourth-col,
      &.five-col {
        width: 0%;
      }
    }
  }
}
