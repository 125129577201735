/* Structure */

.pdf-table {
  table {
    width: 100%;
    font-size: 10px;
  }
  .mat-table thead {
    background-color: whitesmoke;
  }
  tr.mat-row,
  tr.mat-footer-row {
    height: 24px;
  }
  tr.mat-header-row {
    height: 32px;
  }
  .mat-cell,
  .mat-footer-cell,
  .mat-header-cell {
    font-size: 10px;
  }
  th.mat-header-cell:first-of-type,
  td.mat-cell:first-of-type,
  td.mat-footer-cell:first-of-type {
    padding-left: 8px;
  }

  .mat-form-field {
    font-size: 14px;
    width: 100%;
  }
}
