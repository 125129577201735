abbr,
acronym {
  border-bottom: 1px dotted $color__border-abbr;
  cursor: help;
}

mark,
ins {
  background: $color__background-ins;
  text-decoration: none;
}
