// Rem output with px fallback
@mixin font-size($sizeValue: 1) {
  font-size: ($sizeValue * 16) * 1px;
  font-size: $sizeValue * 1rem;
}

// Center block
@mixin center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

// Clearfix
@mixin clearfix() {
  content: "";
  display: table;
  table-layout: fixed;
}

// Clear after (not all clearfix need this also)
@mixin clearfix-after() {
  clear: both;
}

// Column width with margin
@mixin column-width($numberColumns: 3) {
  width: map-get($columns, $numberColumns) -
    (($columns__margin * ($numberColumns - 1)) / $numberColumns);
}

// =============================================================================
// Font smoothing
// =============================================================================

@mixin font-smoothing($value: on) {
  @if $value == on {
    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  } @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}

// =============================================================================
// Pixels to Rems
// =============================================================================

@function strip-unit($num) {
  @return $num / ($num * 0 + 1);
}

@mixin rem-fallback($property, $values...) {
  $max: length($values);
  $pxValues: "";
  $remValues: "";

  @for $i from 1 through $max {
    $value: strip-unit(nth($values, $i));
    $pxValues: #{$pxValues + $value * 16}px;

    @if $i < $max {
      $pxValues: #{$pxValues + " "};
    }
  }

  @for $i from 1 through $max {
    $value: strip-unit(nth($values, $i));
    $remValues: #{$remValues + $value}rem;

    @if $i < $max {
      $remValues: #{$remValues + " "};
    }
  }

  #{$property}: $pxValues;
  #{$property}: $remValues;
}
